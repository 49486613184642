import axios from 'axios';
import { API_BASE_URL, API_TIMEOUT, API_HEADERS } from './config';

// 创建 axios 实例
const request = axios.create({
  baseURL: API_BASE_URL,
  timeout: API_TIMEOUT,
  headers: API_HEADERS,
});

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // 从 localStorage 获取 token
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
    // 成功响应处理
    (response) => {
        // 如果后端返回的格式是 { code, data, message }
        const res = response.data;
        
        if (res.code === 0) {
            return res;  // 直接返回数据部分
        } else {

            if(res.code ===61 && res.message === 'token校验失败'){
                // 未授权，请登录
                localStorage.removeItem('token');
                window.location.href = '/login';
            }

            // 处理业务错误
            const error = new Error(res.message || '请求失败');
            error.code = res.code;
            return Promise.reject(error);
        }
    },
    // 错误响应处理
    (error) => {
        let message = '';
        if (error.response) {
            // 请求已发出，但服务器响应的状态码不在 2xx 范围内
            switch (error.response.status) {
                case 401:
                    message = '未授权，请登录';
                    // 可以在这里处理登录过期的逻辑
                    break;
                case 403:
                    message = '拒绝访问';
                    break;
                case 404:
                    message = '请求错误，未找到该资源';
                    break;
                case 500:
                    message = '服务器错误';
                    break;
                default:
                    message = `连接错误${error.response.status}`;
            }
        } else if (error.request) {
            // 请求已发出，但没有收到响应
            message = '网络错误，请检查网络连接';
        } else {
            // 请求配置有误
            message = error.message;
        }

        console.error('请求错误:', message);
        return Promise.reject(error);
    }
);

export default request; 