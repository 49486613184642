import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '@/styles/Register.css';
import { publicAPI } from '@/api';
import { message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom'; 
const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        username: '',
        mobile: '',
        password: '',
        confirmPassword: '',
        code: '',
        inviteCode: ''
    });

    const [showCaptcha, setShowCaptcha] = useState(false);
    const [captchaCode, setCaptchaCode] = useState({cid: '', code: ''});
    const [captchaImage, setCaptchaImage] = useState(''); // 图片验证码的URL

    // 在组件加载时获取 URL 参数
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const refCode = params.get('ref');
        if (refCode) {
            setFormData(prev => ({
                ...prev,
                inviteCode: refCode
            }));
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // 处理注册逻辑
        if(formData.password !== formData.confirmPassword) {
            message.error('两次密码不一致');
            return;
        }   

        if(formData.password.length < 6) {
            message.error('密码长度不能小于6位');
            return;
        }   

        if(formData.code.length === '') {
            message.error('请输入验证码');
            return;
        }   

        const res = await publicAPI.register(formData);
        if(res.code === 0) {
            message.success('注册成功');
            // 跳转到首页
            navigate('/');
        } else {
            message.error(res.message);
        }
    };

    // 获取图片验证码
    const getCaptchaImage = async () => {
        // 这里调用后端接口获取图片验证码
        const res = await publicAPI.getCaptcha();
        setCaptchaImage(res.data.base64);
        setCaptchaCode({cid: res.data.cid, code: ''});
    };

    // 打开验证码弹窗
    const handleGetCode = () => {

        if(formData.username === '') {
            message.error('请输入用户名');
            return;
        }

        if(formData.mobile.length !== 11) {
            message.error('请输入正确的手机号码');
            return;
        }

        setShowCaptcha(true);
        getCaptchaImage();
    };

    // 刷新验证码
    const refreshCaptcha = () => {
        getCaptchaImage();
    };

    // 确认验证
    const handleCaptchaConfirm = async () => {
        if(captchaCode.code.length === '') {
            message.error('请输入验证码');
            return;
        }

        const res = await publicAPI.smsSend({
            event: "register", //register 注册   resetPwd 找回密码||重置密码
            mobile: formData.mobile,
            cid: captchaCode.cid,
            code: captchaCode.code
        });

        if(res.code === 0) {
            message.success('验证码发送成功');
            setShowCaptcha(false);
        } else {
            message.error(res.message);
        }
    };

    return (
        <div className="register-container">
            <div className="register-box">
                <div className="register-left">
                    <div className="left-content">
                        <div className="brand-logo">BRAND</div>
                        <h1 className="welcome-text">
                            开启您的专属<br />会员之旅
                        </h1>
                        <p className="welcome-desc">
                            加入我们，享受独特的会员权益和专属服务。我们致力于为您提供最优质的体验。
                        </p>
                    </div>
                </div>
                
                <div className="register-right">
                    <div className="form-header">
                        <h2 className="form-title">创建账号</h2>
                        <p className="form-subtitle">
                            {formData.inviteCode ? `您正在通过邀请码 ${formData.inviteCode} 注册` : '请填写以下信息完成注册'}
                        </p>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label className="form-label">用户名</label>
                            <input 
                                type="text"
                                className="form-input"
                                placeholder="请输入用户名"
                                value={formData.username}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    username: e.target.value
                                })}
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label">手机号码</label>
                            <input 
                                type="tel"
                                className="form-input"
                                placeholder="请输入手机号码"
                                value={formData.phone}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    mobile: e.target.value
                                })}
                            />
                           
                        </div>

                        <div className="form-group">
                            <label className="form-label">验证码</label>
                            <input 
                                type="text"
                                className="form-input"
                                placeholder="请输入验证码"
                                value={formData.code}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    code: e.target.value
                                })}
                            />
                             <button className="get-code-button" onClick={handleGetCode}>获取验证码</button>
                        </div>

                        <div className="form-group">
                            <label className="form-label">密码</label>
                            <input 
                                type="password"
                                className="form-input"
                                placeholder="请设置密码"
                                value={formData.password}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    password: e.target.value
                                })}
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label">确认密码</label>
                            <input 
                                type="password"
                                className="form-input"
                                placeholder="请再次输入密码"
                                value={formData.confirmPassword}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    confirmPassword: e.target.value
                                })}
                            />
                        </div>

                        {/* 如果有邀请码，显示但不可编辑 */}
                        {formData.ref && (
                            <div className="form-group">
                                <label className="form-label">邀请码</label>
                                <input 
                                    type="text"
                                    className="form-input form-input-disabled"
                                    value={formData.ref}
                                    disabled
                                />
                            </div>
                        )}

                        <button type="submit" className="register-button">
                            立即注册
                        </button>
                    </form>

                    <div className="terms">
                        注册即表示同意 <a href="#">服务条款</a> 和 <a href="#">隐私政策</a>
                    </div>

                    <div className="login-link">
                        已有账号？<Link to="/login">立即登录</Link>
                    </div>
                </div>
            </div>

            {/* 图片验证码弹窗 */}
            {showCaptcha && (
                <div className="captcha-modal">
                    <div className="captcha-content">
                        <div className="captcha-header">
                            <h3 className="captcha-title">请完成安全验证</h3>
                        </div>
                        
                        <div 
                            className="captcha-image"
                            onClick={refreshCaptcha}
                        >
                            {captchaImage ? (
                                <img src={captchaImage} alt="验证码" />
                            ) : (
                                <span>加载中...</span>
                            )}
                        </div>

                        <input
                            type="text"
                            className="captcha-input"
                            placeholder="请输入图片验证码"
                            value={captchaCode.code}
                            onChange={(e) => setCaptchaCode({
                                ...captchaCode,
                                code: e.target.value
                            })}
                        />

                        <div className="captcha-buttons">
                            <button 
                                className="captcha-button captcha-cancel"
                                onClick={() => setShowCaptcha(false)}
                            >
                                取消
                            </button>
                            <button 
                                className="captcha-button captcha-confirm"
                                onClick={handleCaptchaConfirm}
                            >
                                确认
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Register; 