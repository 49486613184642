import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Table, Typography, Cascader, message, Button } from 'antd';
import { authAPI } from '@/api';

const { Text } = Typography;


function findNodesWithIds(treeData, targetIds) {
    // 过滤并构建新的树形结构
    const filterTree = (nodes) => {
        let filteredNodes = [];

        nodes.forEach((node) => {
            const foundInChildren = node.children && node.children.length > 0 ? filterTree(node.children) : null;
            const hasTargetId = targetIds.includes(node.value);

            // 如果当前节点有目标ID或其子节点中有目标ID，则保留该节点
            if (hasTargetId || foundInChildren) {
                node.children = foundInChildren;
                filteredNodes.push(node);
            }
        });

        return filteredNodes.length > 0 ? filteredNodes : null;
    };

    return filterTree(treeData); // 从根节点开始
}
function setDisabledForId(treeData, id, ok) {
    function traverseNodes(nodes) {
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            if (node.value === id) {
                node.disabled = ok;
            }
            if (node.children) {
                traverseNodes(node.children);
            }
        }
    }

    traverseNodes(treeData);
    return treeData;
}


function getRandomLeafNodeValue(treeData) {
    const allLeafPaths = [];

    function collectLeafPaths(nodes, currentPath = []) {
        for (const node of nodes) {
            const newPath = [...currentPath, node.value];
            
            if (!node.children && node.value > 0 && !node.disabled) {
                // 找到叶子节点且未被禁用时，保存完整路径
                allLeafPaths.push(newPath);
            } else if (node.children) {
                // 继续遍历子节点，传递当前路径
                collectLeafPaths(node.children, newPath);
            }
        }
    }

    collectLeafPaths(treeData);

    if (allLeafPaths.length === 0) {
        return null; // 如果没有符合条件的叶子节点，则返回 null
    }

    // 随机选择一个完整路径
    const randomIndex = Math.floor(Math.random() * allLeafPaths.length);
    return allLeafPaths[randomIndex];
}

const SwitchIPModal = ({
    visible,
    onCancel,
    selectedRowKeys,
    onSuccess,
    availableAddresses
}) => {
    const [switchIPList, setSwitchIPList] = useState([]);
    const [switchIPLoading, setSwitchIPLoading] = useState(false);

    const columns = [
        {
            title: '代理ID',
            dataIndex: 'id',
            key: 'id',
            width: 100,
        },
        {
            title: '游戏',
            dataIndex: 'game_name',
            key: 'game_name',
            width: 150,
        },
        {
            title: '当前IP',
            dataIndex: 'addr',
            key: 'addr',
            width: 150,
        },
        {
            title: '切换到',
            dataIndex: 'targetIP',
            key: 'targetIP',
            width: 300,
            render: (_, record) => (
                <Cascader
                    style={{ width: '100%' }}
                    options={record.AddrSelect}
                    onChange={(value) => handleIPChange(record.id, record.game_id, value)}
                    placeholder="请选择目标IP"
                    showSearch={{
                        filter: (inputValue, path) => {
                            return path.some(option =>
                                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
                            );
                        }
                    }}
                    value={record.targetIP}
                />
            ),
        }
    ];

    const handleIPChange = (proxyId, gameId, value) => {
        // 确保 value 是数组，如果是 undefined 或 null 则设为空数组
        const newValue = value || [];

        // 如果之前有选择，需要先恢复之前的禁用状态
        for (let i = 0; i < switchIPList.length; i++) {
            if (switchIPList[i].game_id === gameId && switchIPList[i].id === proxyId) {
                if (switchIPList[i].targetIP) {
                    // 恢复之前的禁用
                    for (let j = 0; j < switchIPList.length; j++) {
                        if (switchIPList[j].game_id === gameId) {
                            setDisabledForId(switchIPList[j].AddrSelect, switchIPList[i].targetIP[switchIPList[i].targetIP.length - 1], false);
                        }
                    }
                }
            }
        }

        // 只有当选择了新值时才设置禁用
        if (newValue.length > 0) {
            for (let i = 0; i < switchIPList.length; i++) {
                if (switchIPList[i].game_id === gameId && switchIPList[i].id !== proxyId) {
                    setDisabledForId(switchIPList[i].AddrSelect, newValue[newValue.length - 1], true);
                }
            }
        }

        // 更新目标IP
        const updatedList = switchIPList.map(item => {
            if (item.game_id === gameId && item.id === proxyId) {
                return { ...item, targetIP: newValue };
            }
            return item;
        });

        setSwitchIPList(updatedList);
    };

    // 使用 useCallback 记忆化 fetchIPList
    const fetchIPList = useCallback(async () => {
        try {
            setSwitchIPLoading(true);
            const response = await authAPI.jumpAddrQuery({
                id: selectedRowKeys,
            });

            if (response.code === 0) {
                // 
                setSwitchIPList(response.data.list.map(item => ({
                    ...item,
                    targetIP: undefined,
                    AddrSelect: findNodesWithIds(JSON.parse(JSON.stringify(availableAddresses)),item.id_list.split(',').map(id => parseInt(id))),
                })));
            } else {
                message.error('获取IP列表失败：' + response.message);
            }
        } catch (error) {
            message.error('获取IP列表失败：' + error.message);
        } finally {
            setSwitchIPLoading(false);
        }
    }, [selectedRowKeys, availableAddresses]);

    const handleSwitchIPConfirm = async () => {
        const switchRequests = switchIPList
            .filter(item => item.targetIP)
            .map(item => ({
                proxyId: item.id,
                addrId: item.targetIP[item.targetIP.length - 1]
            }));

        if (switchRequests.length === 0) {
            message.warning('请至少选择一个要切换的IP');
            return;
        }

        try {
            setSwitchIPLoading(true);
            const response = await authAPI.jumpAddr({
                data: switchRequests
            });

            if (response.code === 0) {
                message.success(response.data.msg);
                onSuccess();
            } else {
                message.error('切换IP失败：' + response.message);
            }
        } catch (error) {
            message.error('切换IP失败：' + error.message);
        } finally {
            setSwitchIPLoading(false);
        }
    };

    // Modal显示时获取IP列表
    useEffect(() => {
        if (visible) {
            fetchIPList();
        } else {
            setSwitchIPList([]); // Modal关闭时清空列表
        }
    }, [visible, fetchIPList]);

    // 添加随机选择函数
    const handleRandomSelect = () => {
        // 创建一个副本以存储所有更改
        const updatedList = [...switchIPList];

        for (let i = 0; i < updatedList.length; i++) {
            const item = updatedList[i];
            
            // 如果之前有选择，需要先恢复之前的禁用状态
            if (item.targetIP) {
                for (let j = 0; j < updatedList.length; j++) {
                    if (updatedList[j].game_id === item.game_id) {
                        setDisabledForId(updatedList[j].AddrSelect, item.targetIP[item.targetIP.length - 1], false);
                    }
                }
            }

            // 获取随机值（已经包含了对disabled的检查）
            const randomValue = getRandomLeafNodeValue(item.AddrSelect);
            
            if (randomValue !== null) {
                // 设置新的禁用状态
                for (let j = 0; j < updatedList.length; j++) {
                    if (updatedList[j].game_id === item.game_id && updatedList[j].id !== item.id) {
                        setDisabledForId(updatedList[j].AddrSelect, randomValue[randomValue.length - 1], true);
                    }
                }

                // 更新当前项的目标IP
                updatedList[i] = {
                    ...item,
                    targetIP: randomValue
                };
            }
        }

        // 一次性更新状态
        setSwitchIPList(updatedList);
    };

    return (
        <Modal
            title="切换IP"
            open={visible}
            onOk={handleSwitchIPConfirm}
            onCancel={onCancel}
            okText="确认"
            cancelText="取消"
            width={800}
        >
            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text strong>已选择 {selectedRowKeys.length} 个代理进行IP切换</Text>
                <Button onClick={handleRandomSelect}>全部随机选择</Button>
            </div>
            <Table
                columns={columns}
                dataSource={switchIPList}
                loading={switchIPLoading}
                pagination={false}
                size="small"
                scroll={{ y: 400 }}
            />
        </Modal>
    );
};

export default SwitchIPModal; 