import React, { useState, useEffect, useRef } from 'react';
import './ProxyShop.css';
import { authAPI } from '@/api';
import { Form, InputNumber, Radio, Select, Checkbox, Input, Badge, Row, Col, Modal, Button } from 'antd';
import { SignalFilled, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import PaymentConfirmModal from '@/components/proxy-shop/PaymentConfirmModal';
import { message } from 'antd';
import TrialConfirmModal from '@/components/proxy-shop/TrialConfirmModal';
import { useNavigate } from 'react-router-dom';


const ProxyShop = () => {
  const [form] = Form.useForm();
  const gameValue = Form.useWatch('game', form);

  // 购物车
  const [buyList, setBuyList] = useState([]);

  // 协议
  const [protocol, setProtocol] = useState('socks5');

  // 账号密码选择
  const [selectedAccountPassword, setSelectedAccountPassword] = useState(1);
  // 保持账号密码
  const [accountPassword, setAccountPassword] = useState({ username: undefined, password: undefined, method: 'AES-128-GCM' });

  // 保持价格
  const [price, setPrice] = useState(null);

  // 保持选择套餐
  const [selectedPrice, setSelectedPrice] = useState('day30');

  // 获取游戏列表
  const [gameList, setGameList] = useState([]);
  const getGameList = async () => {
    const response = await authAPI.getGameList();
    setGameList(response.data.list.map(item => ({ label: item.name, value: item.id })));

  };

  // 获取价格体系
  const [priceList, setPriceList] = useState([]);
  const getPriceList = async () => {
    const response = await authAPI.getPriceList();
    setPriceList(response.data.list);
  };


  // 获取城市列表
  const [cityList, setCityList] = useState([]);
  const getCityList = async (data) => {
    clearData();
    const response = await authAPI.getCityList(data);
    setCityList(response.data.list);
  };


  // 获取协议配置
  const [protocolInfo, setProtocolInfo] = useState(null);
  const getProtocolInfo = async () => {
    const response = await authAPI.getProtocolInfo();
    setProtocolInfo(response.data);
  };


  // 获取服务器列表
  const [serverList, setServerList] = useState([]);
  const getServerList = async (data) => {
    if (data.cityId === undefined || data.cityId === null || data.cityId.length === 0) {
      setServerList([]);
      return;
    }
    const response = await authAPI.getServerList(data);
    // 去和购物车匹配
    for (let i = 0; i < response.data.list.length; i++) {
      for (let j = 0; j < buyList.length; j++) {
        if (response.data.list[i].serverId === buyList[j].serverId) {
          response.data.list[i].proxyId = response.data.list[i].proxyId.filter(proxyId => proxyId !== buyList[j].proxyId);
        }
      }
    }

    setServerList(response.data.list);
  };

  const navigate = useNavigate();

  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [authGift, setAuthGift] = useState(0);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await authAPI.getMyAccount();
        if (response.data.enableAuth && !response.data.auth && !authModalVisible) {
          setAuthGift(response.data.authGift);
          setAuthModalVisible(true);
        }
      } catch (error) {
        message.error('获取信息失败，请刷新重试');
      }
    };



    fetchUser();
  }, []);

  // 使用单独的 useEffect 来处理弹窗显示
  useEffect(() => {
    if (authModalVisible) {
      Modal.warning({
        title: '实名认证提醒',
        content: `您还未完成实名认证， 完成实名认证后可免费获得${authGift}张试用卡，请先进行实名认证后继续操作。`,
        centered: true,
        closable: false,
        maskClosable: false,
        keyboard: false,
        okText: '去认证',
        onOk: () => navigate('/account/settings'),
        className: 'auth-reminder-modal',
        icon: null,
      });
    }
  }, [authModalVisible, authGift, navigate]);

  const clearData = () => {

    // 清空城市列表
    setCityList([]);
    // 清空服务器列表
    setServerList([]);
    // 清空购物车
    setBuyList([]);
  }

  // 添加新的状态
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTrialModalVisible, setIsTrialModalVisible] = useState(false);



  // 更新购买按钮点击函数
  const [orderInfo, setOrderInfo] = useState(null);
  const buyBtnClick = () => {
    // 获取订单信息
    const orderInfo = {
      gameId: form.getFieldValue('game'),
      priceId: form.getFieldValue('price'),
      addrId: buyList.map(item => item.proxyId),
      type: protocol,
      day: selectedPrice,
      conf: {
        username: accountPassword.username,
        password: accountPassword.password,
        method: accountPassword.method
      }
    }

    if (selectedAccountPassword === 2) {
      if (orderInfo.conf.username === undefined || orderInfo.conf.password === undefined || orderInfo.conf.method === undefined) {
        message.error('请输入账号密码');
        return;
      }

    } else {
      orderInfo.conf = {};
    }


    if (orderInfo.addrId.length === 0) {
      message.error('购物车为空,请选择服务器');
      return;
    }

    if (orderInfo.gameId === undefined || orderInfo.priceId === undefined) {
      message.error('请选择游戏和地区分类');
      return;
    }

    if (orderInfo.day === undefined) {
      message.error('请选择购买时长');
      return;
    }

    setOrderInfo(orderInfo);

    // 显示支付弹窗
    setIsModalVisible(true);
  };

  const TrialBtnClick = () => {
    // 获取订单信息
    const orderInfo = {
      gameId: form.getFieldValue('game'),
      priceId: form.getFieldValue('price'),
      addrId: buyList.map(item => item.proxyId),
      type: protocol,
      day: selectedPrice,
      conf: {
        username: accountPassword.username,
        password: accountPassword.password,
        method: accountPassword.method
      }
    }

    if (selectedAccountPassword === 2) {
      if (orderInfo.conf.username === undefined || orderInfo.conf.password === undefined || orderInfo.conf.method === undefined) {
        message.error('请输入账号密码');
        return;
      }

    } else {
      orderInfo.conf = {};
    }


    if (orderInfo.addrId.length === 0) {
      message.error('购物车为空,请选择服务器');
      return;
    }

    if (orderInfo.gameId === undefined || orderInfo.priceId === undefined) {
      message.error('请选择游戏和地区分类');
      return;
    }

    if (orderInfo.day === undefined) {
      message.error('请选择购买时长');
      return;
    }

    setOrderInfo(orderInfo);
    setIsTrialModalVisible(true);
  }

  const handlePaymentSuccess = (orderData) => {
    clearData();
    // 处理支付成功后的逻辑，比如：
    // - 清空购物车
    // - 刷新订单列表
    // - 跳转到订单详情页
  };

  const handleTrialSuccess = (data) => {
    clearData();
    // 处理试用成功后的逻辑
  };

  useEffect(() => {
    getGameList();
    getPriceList();
    getProtocolInfo();
  }, []);

  const ITEM_HEIGHT = 120; // 每个项目的高度
  const MIN_ITEM_WIDTH = 160; // 最小项目宽度

  // 渲染每个格子的函数
  const Cell = ({ columnIndex, rowIndex, style, data }) => {
    const { items, columnCount } = data;
    const index = rowIndex * columnCount + columnIndex;
    if (index >= items.length) return null;

    const region = items[index];

    return (
      <div style={{
        ...style,
        padding: '1px'
      }}>
        <div className="ps-country-card">
          <div className="ps-country-info">
            <span>
              <SignalFilled style={{ color: '#52c41a' }} />
            </span>
            <span className="ps-country-name">{region.serverName}</span>
          </div>
          <div className="ps-country-total">
            剩余:{region.proxyId.length}
          </div>
          <div className="ps-price">
            <InputNumber
              controls={false}
              addonBefore={<MinusOutlined
                onClick={() => {
                  // 减去购物车
                  const newBuyList = [...buyList];
                  for (let i = 0; i < newBuyList.length; i++) {
                    if (newBuyList[i].serverId === region.serverId) {
                      // 给列表添加数量
                      const newServerList = serverList.map(server => {
                        if (server.serverId === region.serverId) {
                          return {
                            ...server,
                            proxyId: [...server.proxyId, newBuyList[i].proxyId]
                          };
                        }
                        return server;
                      });

                      // 更新服务器列表
                      setServerList(newServerList);
                      // 删除购物车项
                      newBuyList.splice(i, 1);
                      // 更新购物车
                      setBuyList(newBuyList);
                      break;
                    }
                  }
                }}
                style={{ cursor: 'pointer' }}
              />}
              addonAfter={<PlusOutlined style={{ cursor: 'pointer' }} onClick={() => {
                if (region.proxyId[region.proxyId.length - 1] === undefined || region.proxyId[region.proxyId.length - 1] === null) {
                  return;
                }
                // 添加到购物车
                setBuyList([...buyList, { serverId: region.serverId, proxyId: region.proxyId[region.proxyId.length - 1] }]);

                // 更新剩余数量
                region.proxyId = region.proxyId.slice(0, region.proxyId.length - 1);
                setServerList(serverList);

              }} />}
              min={0}
              max={region.count}
              value={region.count - region.proxyId.length}
              defaultValue={0}
              onChange={(value) => {
                if (value === null || value === undefined) return;

                const currentCount = region.count - region.proxyId.length; // 当前购物车中的数量

                if (value > currentCount) {
                  // 加购物车
                  const itemsToAdd = value - currentCount;
                  const newBuyList = [...buyList];
                  const newServerList = serverList.map(server => {
                    if (server.serverId === region.serverId) {
                      const proxyIdsToAdd = server.proxyId.slice(-itemsToAdd);
                      newBuyList.push(...proxyIdsToAdd.map(proxyId => ({
                        serverId: server.serverId,
                        proxyId
                      })));
                      return {
                        ...server,
                        proxyId: server.proxyId.slice(0, -itemsToAdd)
                      };
                    }
                    return server;
                  });

                  setBuyList(newBuyList);
                  setServerList(newServerList);
                } else if (value < currentCount) {
                  // 减购物车
                  const itemsToRemove = currentCount - value;
                  const newBuyList = [...buyList];
                  const itemsRemoved = [];

                  // 从购物车中移除指定数量的项
                  for (let i = newBuyList.length - 1; i >= 0 && itemsRemoved.length < itemsToRemove; i--) {
                    if (newBuyList[i].serverId === region.serverId) {
                      itemsRemoved.push(newBuyList[i].proxyId);
                      newBuyList.splice(i, 1);
                    }
                  }

                  // 更新服务器列表
                  const newServerList = serverList.map(server => {
                    if (server.serverId === region.serverId) {
                      return {
                        ...server,
                        proxyId: [...server.proxyId, ...itemsRemoved]
                      };
                    }
                    return server;
                  });

                  setBuyList(newBuyList);
                  setServerList(newServerList);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="ps-container">
        <div className="ps-main">
          <div className="ps-left">
            <div className="ps-header">
              <div className="ps-title">商城列表</div>
              <div className="ps-banner">
                高质量的游戏代理
                <span className="ps-sale-tag">Sale</span>
              </div>
            </div>

            <Form form={form} layout="vertical" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
              <Form.Item name="game" label="游戏">
                <Select
                  placeholder="请选择游戏"
                  showSearch
                  options={gameList}
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => {
                    clearData();
                    form.setFieldValue('game', value);
                    if (form.getFieldValue('price') !== undefined) {
                      getCityList({
                        gameId: form.getFieldValue('game'),
                        priceId: form.getFieldValue('price')
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="price" label="分类">
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  disabled={!gameValue}
                  onChange={(e) => {
                    form.setFieldValue('price', e.target.value);
                    getCityList({
                      gameId: form.getFieldValue('game'),
                      priceId: e.target.value
                    });
                  }}
                >
                  {priceList.map(d => (
                    <Radio value={d.id} key={d.id} onClick={() => {
                      setPrice(d);
                    }} > {d.name} </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              <Form.Item name="city" label="城市">
                <Checkbox.Group
                  onChange={(value) => {
                    getServerList({
                      gameId: form.getFieldValue('game'),
                      priceId: form.getFieldValue('price'),
                      cityId: value
                    });
                  }}
                >
                  {cityList.map(d => (
                    <div key={d.id} style={{ margin: 5 }}>
                      <Badge count={d.count} size="small">
                        <Checkbox value={d.id} > {d.title} </Checkbox>
                      </Badge>
                    </div>
                  ))}
                </Checkbox.Group>
              </Form.Item>

            </Form>


            <div className="ps-countries">
              <div className="ps-section-title">服务器列表</div>
              <div className="ps-countries-list" style={{ height: '500px' }}>
                <AutoSizer>
                  {({ height, width }) => {
                    // 根据容器宽度计算列数
                    const columnCount = Math.max(1, Math.floor(width / MIN_ITEM_WIDTH));
                    // 计算实际项目宽度
                    const columnWidth = width / columnCount;

                    return (
                      <Grid
                        columnCount={columnCount}
                        columnWidth={columnWidth}
                        height={height}
                        rowCount={Math.ceil(serverList.length / columnCount)}
                        rowHeight={ITEM_HEIGHT}
                        width={width}
                        itemData={{ items: serverList, columnCount }}
                      >
                        {Cell}
                      </Grid>
                    );
                  }}
                </AutoSizer>
              </div>
            </div>
          </div>

          <div>
            <div className="ps-order">
              <div className="ps-order-header">
                <span>订单信息</span>
                <button className="ps-clear" onClick={() => {
                  clearData();
                  setBuyList([]);
                }}  >清空</button>
              </div>

              <div className="ps-order-content">
                <div className="ps-service">
                  <div className="ps-service-item">
                    <span>IP数量</span>
                    <span>{buyList.length} IPs</span>
                  </div>
                  <div className="ps-service-item">
                    <span>协议信息</span>
                    <span>
                      <Radio.Group
                        buttonStyle="solid"
                        onChange={(e) => {
                          setProtocol(e.target.value);
                        }}
                        value={protocol}
                      >
                        <Radio disabled={!protocolInfo?.socks} value={'socks5'}>Http/Socks5</Radio>
                        <Radio disabled={!protocolInfo?.shadowSocks} value={'ss'}>SS</Radio>
                      </Radio.Group>
                    </span>
                  </div>
                  <div className="ps-service-item">
                    <span>账号/密码</span>
                    <span>
                      <Radio.Group
                        buttonStyle="solid"
                        onChange={(e) => {
                          setSelectedAccountPassword(e.target.value);
                        }}
                        value={selectedAccountPassword}
                      >
                        <Radio value={1}>自动生成</Radio>
                        <Radio value={2}>自定义</Radio>
                      </Radio.Group>
                    </span>
                  </div>
                  {selectedAccountPassword === 2 && (
                    protocol === 'socks5' ? (
                      <div className="ps-service-item">
                        <div className="custom-auth">
                          <div>
                            <span>账号：</span>
                            <Input placeholder="请输入账号" value={accountPassword.username} onChange={(e) => {
                              setAccountPassword({ ...accountPassword, username: e.target.value });
                            }} />
                          </div>
                          <div>
                            <span>密码：</span>
                            <Input placeholder="请输入密码" value={accountPassword.password} onChange={(e) => {
                              setAccountPassword({ ...accountPassword, password: e.target.value });
                            }} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="ps-service-item">
                        <div className="custom-auth">
                          <div>
                            <span>加密：</span>
                            <Select
                              placeholder="请选择加密方式"
                              value={accountPassword.method}
                              style={{ width: '100%' }}
                              options={[
                                { label: 'AES-128-GCM', value: 'AES-128-GCM' },
                                { label: 'AES-192-GCM', value: 'AES-192-GCM' },
                                { label: 'AES-256-GCM', value: 'AES-256-GCM' },
                              ]}
                              onChange={(value) => {
                                setAccountPassword({ ...accountPassword, method: value });
                              }}
                            />
                          </div>
                          <div>
                            <span>密码：</span>
                            <Input placeholder="请输入密码" value={accountPassword.password} onChange={(e) => {
                              setAccountPassword({ ...accountPassword, password: e.target.value });
                            }} />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  <div className="ps-service-item">
                    <span>购买时长</span>
                  </div>
                  <div className="ps-service-item">
                    <div>
                      <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        onChange={(e) => {
                          setSelectedPrice(e.target.value);
                        }}
                        value={selectedPrice}
                        size="small"
                        style={{
                          width: '320px'
                        }}
                      >
                        <Radio value={'day7'}>7天</Radio>
                        <Radio value={'day30'}>30天</Radio>
                        <Radio value={'day90'}>90天</Radio>
                        <Radio value={'day180'}>180天</Radio>
                        <Radio value={'day360'}>360天</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>

                <div className="ps-total">
                  <span>总计:</span>
                  <span className="ps-amount">{(() => {
                    switch (selectedPrice) {
                      case 'day7':
                        return (price?.day7 * buyList.length).toFixed(2);
                      case 'day30':
                        return (price?.day30 * buyList.length).toFixed(2);
                      case 'day90':
                        return (price?.day90 * buyList.length).toFixed(2);
                      case 'day180':
                        return (price?.day180 * buyList.length).toFixed(2);
                      case 'day360':
                        return (price?.day360 * buyList.length).toFixed(2);
                    }
                    return '0.00';
                  })()}</span>
                </div>

                <Row>
                  <Col span={7}>
                    <button className="ps-buy-btn" onClick={TrialBtnClick}>
                      试用
                    </button>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={15}>
                    <button className="buy-btn" onClick={buyBtnClick}>
                      现在购买 →
                    </button>
                  </Col>
                </Row>

                <div className="ps-security"    >
                  ✓ 100%安全交易
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <PaymentConfirmModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onSuccess={handlePaymentSuccess}
        totalAmount={(() => {
          switch (selectedPrice) {
            case 'day7': return (price?.day7 * buyList.length).toFixed(2);
            case 'day30': return (price?.day30 * buyList.length).toFixed(2);
            case 'day90': return (price?.day90 * buyList.length).toFixed(2);
            case 'day180': return (price?.day180 * buyList.length).toFixed(2);
            case 'day360': return (price?.day360 * buyList.length).toFixed(2);
            default: return '0.00';
          }
        })()}
        orderInfo={orderInfo}
      />
      <TrialConfirmModal
        visible={isTrialModalVisible}
        onCancel={() => setIsTrialModalVisible(false)}
        onSuccess={handleTrialSuccess}
        orderInfo={orderInfo}
      />
    </>
  );
};

export default ProxyShop; 