import React, { useState, useEffect } from 'react';
import { Card, Tabs, Form, Input, Button, Descriptions, message, Steps, Result } from 'antd';
import { UserOutlined, LockOutlined, IdcardOutlined, CheckCircleOutlined } from '@ant-design/icons';
import styles from './Settings.module.css';
import { authAPI } from '@/api';

const SettingsPage = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [verifyStep, setVerifyStep] = useState(0);


    // 页面初始化
    useEffect(() => {
        getAccountInfo();
    }, []);

    // 网络获取我的账户信息
    const [accountInfo, setAccountInfo] = useState(null);
    const getAccountInfo = async () => {
        const { data } = await authAPI.getMyAccount();
        setAccountInfo(data);
        if (data.auth) return setVerifyStep(2);
    };

    // 处理密码修改
    const handlePasswordChange = async (values) => {
        try {
            setLoading(true);

            // 验证密码
            if (values.newPassword !== values.confirmPassword) {
                message.error('两次输入的密码不一致');
                return;
            }

            const res = await authAPI.changePassword({
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
            });
            if (res.code === 0) {
                message.success('密码修改成功');
                form.resetFields();
            } else {
                message.error(res.message);
            }

        } catch (error) {
            message.error('密码修改失败：' + error.message);
        } finally {
            setLoading(false);
        }
    };

    // 处理实名认证
    const handleVerification = async (values) => {
        try {
            setLoading(true);
            setVerifyStep(1);
            // TODO: 调用实名认证 API
            const res = await authAPI.nameAuth({
                name: values.realName,
                idCard: values.idNumber,
            });

            if (res.code === 0) {
                message.success('实名认证提交成功');
                setVerifyStep(2);
            } else {
                message.error(res.message);
            }

        } catch (error) {
            message.error('实名认证失败：' + error.message);
            setVerifyStep(0);
        } finally {
            setLoading(false);
        }
    };

    // 个人信息 Tab
    const ProfileTab = () => (
        <div className={styles.profileContainer}>
            <Descriptions
                bordered
                column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
            >
                <Descriptions.Item label="用户名">{accountInfo?.username}</Descriptions.Item>
                <Descriptions.Item label="手机号码">{accountInfo?.mobile || '未绑定'}</Descriptions.Item>
                <Descriptions.Item label="注册时间">{accountInfo?.createdAt}</Descriptions.Item>
                <Descriptions.Item label="账户余额">¥{accountInfo?.money || '0.00'}</Descriptions.Item>
                <Descriptions.Item label="账户积分">{accountInfo?.integral || '0'}</Descriptions.Item>
                <Descriptions.Item label="实名状态">
                    <span className={accountInfo?.auth ? styles.verifiedTag : styles.unverifiedTag}>
                        {accountInfo?.auth ? '已认证' : '未认证'}
                    </span>
                </Descriptions.Item>
            </Descriptions>
        </div>
    );

    // 修改密码 Tab
    const PasswordTab = () => (
        <div className={styles.passwordContainer}>
            <Form
                form={form}
                layout="vertical"
                onFinish={handlePasswordChange}
                className={styles.formContainer}
            >
                <Form.Item
                    name="oldPassword"
                    label="当前密码"
                    rules={[{ required: true, message: '请输入当前密码' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="请输入当前密码"
                        autoComplete="off"
                    />
                </Form.Item>

                <Form.Item
                    name="newPassword"
                    label="新密码"
                    rules={[
                        { required: true, message: '请输入新密码' },
                        { min: 6, message: '密码长度不能小于6位' }
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="请输入新密码"
                        autoComplete="new-password"
                    />
                </Form.Item>

                <Form.Item
                    name="confirmPassword"
                    label="确认新密码"
                    dependencies={['newPassword']}
                    rules={[
                        { required: true, message: '请确认新密码' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('两次输入的密码不一致'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder="请再次输入新密码"
                        autoComplete="new-password"
                    />
                </Form.Item>

                <Form.Item className={styles.submitButton}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className={styles.submitBtn}
                        block
                    >
                        确认修改
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

    // 实名认证 Tab
    const VerificationTab = () => {
        const steps = [
            {
                title: '填写信息',
                description: '输入真实姓名和身份证号',
            },
            {
                title: '验证中',
                description: '正在验证身份信息',
            },
            {
                title: '完成认证',
                description: '实名认证完成',
            },
        ];

        const renderStepContent = () => {
            switch (verifyStep) {
                case 0:
                    return (
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleVerification}
                            className={styles.formContainer}
                        >
                            <Form.Item
                                name="realName"
                                label="真实姓名"
                                rules={[
                                    { required: true, message: '请输入真实姓名' },
                                    { pattern: /^[\u4e00-\u9fa5]{2,}$/, message: '请输入正确的中文姓名' }
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined />}
                                    placeholder="请输入真实姓名"
                                    maxLength={20}
                                />
                            </Form.Item>

                            <Form.Item
                                name="idNumber"
                                label="身份证号"
                                rules={[
                                    { required: true, message: '请输入身份证号' },
                                    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号' }
                                ]}
                            >
                                <Input
                                    prefix={<IdcardOutlined />}
                                    placeholder="请输入身份证号"
                                    maxLength={18}
                                />
                            </Form.Item>

                            <Form.Item className={styles.submitButton}>
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    提交认证
                                </Button>
                            </Form.Item>
                        </Form>
                    );
                case 1:
                    return (
                        <div className={styles.stepContent}>
                            <Result
                                title="正在验证身份信息"
                                subTitle="请稍候，我们正在验证您的身份信息"
                                icon={<div className={styles.loadingIcon}></div>}
                            />
                        </div>
                    );
                case 2:
                    return (
                        <div className={styles.stepContent}>
                            <Result
                                status="success"
                                title="实名认证成功"
                                subTitle="您的身份信息已验证通过"
                                icon={<CheckCircleOutlined className={styles.successIcon} />}
                            />
                        </div>
                    );
                default:
                    return null;
            }
        };

        return (
            <div className={styles.verificationContainer}>
                <Steps
                    current={verifyStep}
                    items={steps}
                    className={styles.steps}
                />
                <div className={styles.stepContentWrapper}>
                    {renderStepContent()}
                </div>
            </div>
        );
    };

    const tabItems = [
        {
            key: 'profile',
            label: '个人信息',
            icon: <UserOutlined />,
            children: <ProfileTab />
        },
        {
            key: 'password',
            label: '修改密码',
            icon: <LockOutlined />,
            children: <PasswordTab />
        },
        {
            key: 'verification',
            label: '实名认证',
            icon: <IdcardOutlined />,
            children: <VerificationTab />
        }
    ];

    return (
        <Card className={styles.container}>
            <Tabs
                defaultActiveKey={accountInfo?.auth ? 'profile' : 'verification'}
                items={tabItems}
                className={styles.tabs}
            />
        </Card>
    );
};

export default SettingsPage; 