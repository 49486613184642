import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { 
    UsergroupAddOutlined, 
    DollarOutlined, 
    LinkOutlined,
    RiseOutlined,
    CopyOutlined,
    ThunderboltOutlined
} from '@ant-design/icons';
import { useAuth } from '@/contexts/AuthContext';
import './PromotionIntro.css';
import { publicAPI } from '@/api';

const calculateCommission = (amount, percentage) => {
    // 检查 percentage 是否存在
    if (!percentage) return '0.00';
    
    try {
        // 移除百分号并转换为数字
        const rate = parseFloat(percentage.replace('%', '')) / 100;
        return (amount * rate).toFixed(2);
    } catch (error) {
        console.error('计算返利出错:', error);
        return '0.00';
    }
};

const PromotionIntro = () => {

    const [config, setConfig] = useState({});
    useEffect(() => {
        publicAPI.getConfig().then(res => {
            setConfig(res.data);
        });
    }, []);

    const { user } = useAuth();
    const promotionLink = `${window.location.origin}/register?ref=${user?.id}`;

    const copyLink = () => {
        navigator.clipboard.writeText(promotionLink);
        message.success('推广链接已复制到剪贴板');
    };

    const features = [
        {
            icon: <UsergroupAddOutlined />,
            title: "邀请无限制",
            desc: "邀请好友数量无上限，收益永久有效"
        },
        {
            icon: <DollarOutlined />,
            title: "高额回报",
            desc: `永久享受好友充值金额${config.tgfc}的返利`
        },
        {
            icon: <RiseOutlined />,
            title: "收益累计",
            desc: "好友每次充值都能获得返利，永久有效"
        }
    ];

    const steps = [
        {
            number: "01",
            title: "复制推广链接",
            desc: "获取您专属的推广链接"
        },
        {
            number: "02",
            title: "分享好友",
            desc: "通过社交媒体分享给好友"
        },
        {
            number: "03",
            title: "好友注册",
            desc: "好友通过链接完成注册"
        },
        {
            number: "04",
            title: "获得返利",
            desc: `好友充值时您获得${config.tgfc}的返利`
        }
    ];

    return (
        <div className="promotion-page">
            {/* 动态背景 */}
            <div className="promotion-bg">
                <div className="gradient-overlay"></div>
                <div className="particles"></div>
            </div>

            {/* 主要内容 */}
            <div className="promotion-content">
                {/* 顶部英雄区 */}
                <div className="hero-section">
                    <ThunderboltOutlined className="hero-icon" />
                    <h1>推广赚钱计划</h1>
                    <p className="hero-subtitle">
                        <span>邀请好友</span>
                        <span className="dot"></span>
                        <span>永久返利</span>
                        <span className="dot"></span>
                        <span>收益无上限</span>
                    </p>
                </div>

                {/* 特点展示 */}
                <div className="features-section">
                    {features.map((feature, index) => (
                        <div key={index} className="feature-card" data-aos="fade-up" data-aos-delay={index * 100}>
                            <div className="feature-icon">{feature.icon}</div>
                            <h3>{feature.title}</h3>
                            <p>{feature.desc}</p>
                        </div>
                    ))}
                </div>

                {/* 推广链接 */}
                <div className="link-section" data-aos="fade-up">
                    <div className="link-card">
                        <h3>您的专属推广链接</h3>
                        <div className="link-input-group">
                            <Input 
                                value={promotionLink}
                                readOnly
                                prefix={<LinkOutlined />}
                            />
                            <Button 
                                type="primary"
                                icon={<CopyOutlined />}
                                onClick={copyLink}
                            >
                                复制链接
                            </Button>
                        </div>
                    </div>
                </div>

                {/* 操作步骤 */}
                <div className="steps-section">
                    <h2>如何开始推广</h2>
                    <div className="steps-grid">
                        {steps.map((step, index) => (
                            <div 
                                key={index} 
                                className="step-card"
                                data-aos="fade-up"
                                data-aos-delay={index * 100}
                            >
                                <div className="step-number">{step.number}</div>
                                <h3>{step.title}</h3>
                                <p>{step.desc}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* 收益说明 */}
                <div className="earnings-section" data-aos="fade-up">
                    <div className="earnings-card">
                        <h2>收益说明</h2>
                        <div className="earnings-example">
                            <div className="example-item">
                                <div className="example-amount">¥1,000</div>
                                <div className="example-desc">好友充值</div>
                            </div>
                            <div className="example-arrow">
                                <RiseOutlined />
                            </div>
                            <div className="example-item highlight">
                                <div className="example-amount">
                                    ¥{calculateCommission(1000, config?.tgfc)}
                                </div>
                                <div className="example-desc">您的收益</div>
                            </div>
                        </div>
                        <p className="earnings-note">
                            永久有效，收益无上限
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromotionIntro; 