import request from './request';

export const publicAPI = {
  // 获取验证码
  getCaptcha: () => {
    return request({
      url: '/captcha',
      method: 'get'
    });
  },
  // 登录（用户名）
  loginUsername: (data) => {
    return request({
      url: '/login_username',
      method: 'post',
      data
    });
  },
  // 登录（手机号）
  loginMobile: (data) => {
    return request({
      url: '/login_mobile',
      method: 'post',
      data
    });
  },
  // 获取短信验证码
  getSmsCode: (data) => {
    return request({
      url: '/smsSend',
      method: 'post',
      data
    });
  },
  // 注册
  register: (data) => {
    return request({
      url: '/register',
      method: 'post',
      data
    });
  },
  // 找回密码
  findPassword: (data) => {
    return request({
      url: '/retrieve',
      method: 'post',
      data
    });
  },
  // 获取充值赠送列表
  getGiffList: () => {
    return request({
      url: '/giff',
      method: 'get'
    });
  },
  // 获取配置
  getConfig: () => {
    return request({
      url: '/config',
      method: 'post'
    });
  },
  // 验证图片验证码
  smsSend: (data) => {
    return request({
      url: '/smsSend',
      method: 'post',
      data
    });
  },
  // 注册 
  register: (data) => {
    return request({
      url: '/register',
      method: 'post',
      data
    });
  },
  resetPassword: (data) => {
    return request({
      url: '/retrieve',
      method: 'post',
      data
    });
  }
}; 
