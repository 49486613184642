import React from 'react';
import '@/styles/Index.css';
import traffic from '@/assets/traffic.png';
import excellent from '@/assets/excellent.png';
import ok from '@/assets/ok.png';
import { useNavigate } from 'react-router-dom';

const IndexPage = () => {
    const navigate = useNavigate();
    return (
        <div style={{ marginTop: '64px' }}>
            <div style={{ backgroundColor: '#f7f9ff' }}>
                <div className="hero-content">
                    <div className="hero-left">
                        <div className="hero-left-top">
                            <img src={excellent} alt="hero" style={{ width: '257px', height: '21px' }} />
                        </div>
                        <h1 style={{ marginTop: '20px' }}>专业的游戏代理提供商</h1>
                        <p>稳定高效，让您的代理体验更加完美</p>

                        <ul className='itms'>
                            <li className="list-item">
                                <div className="list-item-content">
                                    <img src={ok} alt="确认标记" style={{ width: '20px', height: '20px' }} />
                                    <span style={{ marginLeft: '10px', fontSize: '16px' }}>快速、可靠和高校的代理</span>
                                </div>
                            </li>
                            <li className="list-item">
                                <div className="list-item-content">
                                    <img src={ok} alt="确认标记" style={{ width: '20px', height: '20px' }} />
                                    <span style={{ marginLeft: '10px', fontSize: '16px' }}>价格低至9.9元</span>
                                </div>
                            </li>
                            <li className="list-item">
                                <div className="list-item-content">
                                    <img src={ok} alt="确认标记" style={{ width: '20px', height: '20px' }} />
                                    <span style={{ marginLeft: '10px', fontSize: '16px' }}>非常稳定的代理与99.5%的正常运行时间</span>
                                </div>
                            </li>
                        </ul>

                        <div className="search-bar">
                            <div className="new-button-wrapper">
                                <div className="permanent-tooltip">
                                    注册就送3张测试卡，每个试用卡可免费试用24小时
                                </div>
                                <button className="new-button" data-report="Buy Now" onClick={() => navigate('/register')}>
                                    <span>立即注册</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="hero-right">
                        <img src={traffic} alt="hero" />
                    </div>
                </div>
            </div>

            <div className="advantages">
                <h2 className="section-title">为什么选择我们</h2>
                <div className="advantages-grid">
                    <div className="advantage-card">
                        <div className="advantage-icon">🔒</div>
                        <h3>安全可靠</h3>
                        <p>采用军事级加密技术，确保您的数据安全</p>
                    </div>
                    <div className="advantage-card">
                        <div className="advantage-icon">⚡</div>
                        <h3>极速体验</h3>
                        <p>优质线路，超低延迟，畅享极速网络</p>
                    </div>
                    <div className="advantage-card">
                        <div className="advantage-icon">🌍</div>
                        <h3>全球覆盖</h3>
                        <p>190+国家地区，满足您的全球业务需求</p>
                    </div>
                    <div className="advantage-card">
                        <div className="advantage-icon">💰</div>
                        <h3>价格优势</h3>
                        <p>灵活的套餐选择，最优性价比</p>
                    </div>
                </div>
            </div>

            <div className="pricing">
                <h2 className="section-title">套餐方案</h2>
                <div className="pricing-cards">
                    <div className="pricing-card">
                        <div className="pricing-header">
                            <h3>基础版</h3>
                            <div className="price">
                                <span className="amount">10</span>
                                <span className="period">/月</span>
                            </div>
                        </div>
                        <ul className="features">
                            <li>无限流量</li>
                            <li>大并发连接</li>
                            <li>全球节点访问</li>
                            <li>7×24小时支持</li>
                        </ul>
                       
                    </div>

                    <div className="pricing-card featured">
                        <div className="pricing-header">
                            <h3>专业版</h3>
                            <div className="price">
                                <span className="amount">25</span>
                                <span className="period">/月</span>
                            </div>
                        </div>
                        <ul className="features">
                            <li>无限流量</li>
                            <li>超大并发连接</li>
                            <li>优先节点访问</li>
                            <li>专属客服支持</li>
                        </ul>
                        
                    </div>

                    <div className="pricing-card">
                        <div className="pricing-header">
                            <h3>企业版</h3>
                            <div className="price">
                                <span className="amount">*</span>
                                <span className="period">/月</span>
                            </div>
                        </div>
                        <ul className="features">
                            <li>不限流量</li>
                            <li>无限并发连接</li>
                            <li>专属节点池</li>
                            <li>24/7专属支持</li>
                        </ul>
                        
                    </div>
                </div>
            </div>

            <div className="testimonials">
                <h2 className="section-title">客户评价</h2>
                <div className="testimonials-grid">
                    <div className="testimonial-card">
                        <div className="testimonial-content">
                            "服务稳定性非常好，客服响应速度快，是值得信赖的合作伙伴。"
                        </div>
                        <div className="testimonial-author">
                            <div className="author-name">张经理</div>
                            <div className="author-company">某跨境电商</div>
                        </div>
                    </div>
                    <div className="testimonial-card">
                        <div className="testimonial-content">
                            "性价比很高，全球节点覆盖广��满足了我们的所有需求。"
                        </div>
                        <div className="testimonial-author">
                            <div className="author-name">李总监</div>
                            <div className="author-company">某数据分析公司</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cta-section">
                <h2>准备开始使用了吗？</h2>
                <p>立即注册，享受高质量的代理服务</p>
                <button className="cta-button">开始使用</button>
            </div>

            <div className="floating-service">
                <button className="service-button" onClick={() => {
                    document.querySelector('.service-popup').classList.toggle('show');
                }}>
                    <span className="service-icon">💬</span>
                    <span className="service-text">在线客服</span>
                </button>
                <div className="service-popup">
                    <div className="popup-header">
                        <h4>联系客服</h4>
                        <button className="close-button" onClick={() => {
                            document.querySelector('.service-popup').classList.remove('show');
                        }}>×</button>
                    </div>
                    <div className="popup-content">
                        <div className="qq-info">
                            <span className="qq-icon">QQ</span>
                            <span className="qq-number">865583446</span>
                        </div>
                        <button className="copy-button" onClick={() => {
                            navigator.clipboard.writeText('865583446');
                            alert('QQ号已复制到剪贴板');
                        }}>
                            复制QQ号
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndexPage; 