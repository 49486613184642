import React, { useState, useEffect } from 'react';
import '@/styles/Login.css';
import { publicAPI } from '@/api';
import { message } from 'antd';
import { useAuth } from '@/contexts/AuthContext';
import { UserOutlined, LockOutlined, MobileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const LoginModal = ({ isOpen, onClose, initialMode = 'login' }) => {

    // 模式
    const [mode, setMode] = useState(initialMode); // 'login' 或 'login_mobile'

    // 验证码
    const [captcha, setCaptcha] = useState(null);

    // 登陆表单 state
    const [formLoginData, setFormLoginData] = useState({
        mobile: '',
        username: '',
        password: '',
        cid: '',
        code: ''
    });

    const { login } = useAuth();  // 使用 auth context

    // 处理输入变化
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormLoginData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // 处理表单提交
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (mode === 'login') {
            try {
                const loginData = {
                    username: formLoginData.username,
                    password: formLoginData.password,
                    cid: formLoginData.cid,
                    code: formLoginData.code
                };
                const response = await publicAPI.loginUsername(loginData);

                // 直接传入整个响应数据
                login(response);

                setFormLoginData({
                    mobile: '',
                    username: '',
                    password: '',
                    cid: '',
                    code: ''
                });
                message.success('登录成功');
                onClose();
            } catch (error) {
                message.error(error.message || '登录失败，请重试');
                getCaptcha();
            }
        } else if (mode === 'login_mobile') {
            try {
                const loginData = {
                    mobile: formLoginData.mobile,
                    password: formLoginData.password,
                    cid: formLoginData.cid,
                    code: formLoginData.code
                };
                const response = await publicAPI.loginMobile(loginData);

                // 直接传入整个响应数据
                login(response);

                setFormLoginData({
                    mobile: '',
                    password: '',
                    cid: '',
                    code: ''
                });
                message.success('登录成功');
                onClose();
            } catch (error) {
                message.error(error.message || '登录失败，请重试');
                getCaptcha();
            }
        }
    };

    // 获取验证码
    const getCaptcha = async () => {
        const response = await publicAPI.getCaptcha();
        setCaptcha(response.data.base64);
        setFormLoginData(prev => ({
            ...prev,
            cid: response.data.cid
        }));
    };

    // 页面初始化获取验证码
    useEffect(() => {
        getCaptcha();
    }, []);


    // 如果模态框未打开，则不渲染
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>×</button>

                <div className="auth-tabs">
                    <button
                        className={`tab ${mode === 'login' ? 'active' : ''}`}
                        onClick={() => setMode('login')}
                    >
                        用户名登录
                    </button>
                    <button
                        className={`tab ${mode === 'login_mobile' ? 'active' : ''}`}
                        onClick={() => setMode('login_mobile')}
                    >
                        手机号登录
                    </button>
                </div>

                <form className="auth-form" onSubmit={handleSubmit}>

                    {mode === 'login' ? (
                        <div className="input-wrapper">
                            <UserOutlined className="input-icon" />
                            <input
                                className="input-with-icon"
                                type="text"
                                name="username"
                                value={formLoginData.username}
                                onChange={handleInputChange}
                                placeholder="用户名"
                                required
                            />
                        </div>
                    ) : (
                        <div className="input-wrapper">
                            <MobileOutlined className="input-icon" />
                            <input
                                className="input-with-icon"
                                type="text"
                                name="mobile"
                                value={formLoginData.mobile}
                                onChange={handleInputChange}
                                placeholder="手机号"
                                required
                            />
                        </div>
                    )}

                    <div className="input-wrapper">
                        <LockOutlined className="input-icon" />
                        <input
                            className="input-with-icon"
                            type="password"
                            name="password"
                            value={formLoginData.password}
                            onChange={handleInputChange}
                            placeholder="密码"
                            required
                        />
                    </div>
                    <div className="captcha-container">
                        <input
                            type="text"
                            name="code"
                            value={formLoginData.code}
                            onChange={handleInputChange}
                            placeholder="验证码"
                            required
                        />
                        <img
                            style={{ width: '100px', height: '50px' }}
                            src={captcha}
                            alt="验证码"
                            onClick={getCaptcha}
                            className="captcha-image"
                        />
                    </div>
                    <button type="submit">登录</button>
                    <p className="register-link"> <Link to="/reset-password" onClick={()=>{onClose()}} >忘记密码？</Link></p>
                </form>

            </div>
        </div>
    );
};

export default LoginModal; 