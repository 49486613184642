import { Layout, Menu, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import '@/styles/Navbar.css';
import logo from '@/assets/logo.png';
import LoginModal from '@/components/Login';
import { useAuth } from '@/contexts/AuthContext';
import { authAPI } from '@/api';

const { Header } = Layout;

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user, isAuthenticated, logout } = useAuth();


    // 监听路由变化
    useEffect(() => {
        if (location.pathname === '/login') {
            setIsModalOpen(true);
        }
    }, [location]);

    const handleLogout = async () => {
        try {
            await authAPI.logout();
            await logout();
            navigate('/');
        } catch (error) {
            console.error('退出登录失败:', error);
        }
    };

    const currentPath = window.location.pathname;

    const menuItems = [
        {
            key: '/',
            label: <Link to="/">首页</Link>
        },
        {
            key: '/proxy-shop',
            label: isAuthenticated ? (
                <Link to="/proxy-shop">代理商城</Link>
            ) : (
                <span onClick={() => setIsModalOpen(true)}>代理商城</span>
            )
        },
        {
            key: '/recharge',
            label: <Link to="/recharge">充值活动</Link>
        },
        {
            key: '/promotion',
            label: <Link to="/promotion">推广中心</Link>
        }
    ];

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <Header className="header">
            <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
                <img src={logo} alt="logo" />
            </div>

            <Menu
                className="menu"
                mode="horizontal"
                selectedKeys={[currentPath]}
                items={menuItems}
                style={{ background: 'transparent' }}
            />

            <div className="button-group">
                {isAuthenticated ? (
                    <div className="user-info" style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        flexDirection: 'row',
                    }}>
                        <span style={{ whiteSpace: 'nowrap' }}>
                            Hi, {user.username}
                        </span>
                        <Link to="/account" style={{ display: 'inline-block' }}>
                            <Button 
                                type="link" 
                                style={{ padding: '4px 8px' }}
                            >
                                会员中心
                            </Button>
                        </Link>
                        <Button
                            type="text"
                            danger
                            style={{ 
                                padding: '4px 8px',
                                width: 'auto',
                                minWidth: 'auto',
                                flex: '0 0 auto',
                            }}
                            onClick={handleLogout}
                        >
                            退出登录
                        </Button>
                    </div>
                ) : (
                    <div className="user-info" style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        flexDirection: 'row',
                    }}>
                        <Button
                            type="link"
                            style={{ 
                                padding: '4px 8px',
                                width: 'auto',
                                minWidth: 'auto',
                                flex: '0 0 auto',
                            }}
                            onClick={() => setIsModalOpen(true)}
                        >
                            登录
                        </Button>
                        <Button
                            type="text"
                            style={{ 
                                padding: '4px 8px',
                                width: 'auto',
                                minWidth: 'auto',
                                flex: '0 0 auto',
                            }}
                            onClick={() => navigate('/register')}
                        >
                            注册
                        </Button>
                    </div>
                )}
            </div>

            <MenuOutlined
                className="menu-button"
                onClick={() => setDrawerVisible(true)}
                style={{ fontSize: '20px' }}
            />

            <Drawer
                open={drawerVisible}
                onClose={() => setDrawerVisible(false)}
                placement="right"
                width={250}
            >
                <Menu
                    mode="vertical"
                    selectedKeys={[currentPath]}
                    items={menuItems}
                    style={{ border: 'none' }}
                />
            </Drawer>

            <LoginModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </Header>
    );
};

export default Navbar; 