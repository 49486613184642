import React, { useState, useEffect } from 'react';
import { Table, message, Button, Space, Tooltip, Tag, Input, DatePicker } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import styles from './Proxy.module.css';
import { authAPI } from '@/api';
import SwitchIPModal from './components/SwitchIPModal';
import SwitchGameModal from './components/SwitchGameModal';
import RenewModal from './components/RenewModal';
import ModifyProxyModal from './components/ModifyProxyModal';

const { RangePicker } = DatePicker;

// 在文件顶部定义全局变量
let availableAddresses = [];
let availableGames = [];


const ProxyPage = () => {
    const [loading, setLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 条记录`,
    });
    const [filters, setFilters] = useState({
        gameName: '',
        addrString: '',
        port: '',
        deleteTime: [],
    });
    const [isRenewModalVisible, setIsRenewModalVisible] = useState(false);
    const [isSwitchGameModalVisible, setIsSwitchGameModalVisible] = useState(false);
    const [isSwitchIPModalVisible, setIsSwitchIPModalVisible] = useState(false);
    const [isModifyModalVisible, setIsModifyModalVisible] = useState(false);

    // 复制文本到剪贴板
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            message.success('复制成功');
        }).catch(() => {
            message.error('复制失败');
        });
    };

    const columns = [
        {
            title: '游戏',
            dataIndex: 'game',
            key: 'game',
            width: 120,
        },
        {
            title: '线路',
            dataIndex: 'priceName',
            key: 'priceName',
            width: 120,
            render: (text) => <Tag color="blue">{text}</Tag>
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
            width: 120,
            render: (text) => <Tag color="green">{text}</Tag>
        },
        {
            title: '服务器',
            dataIndex: 'serverName',
            key: 'serverName',
            width: 120,
        },
        {
            title: 'IP地址',
            key: 'ip',
            width: 200,
            render: (_, record) => (
                <Space>
                    <span>{`${record.addr}:${record.port}`}</span>
                    <Tooltip title="复制代理">
                        <Button 
                            type="link" 
                            icon={<CopyOutlined />} 
                            onClick={() => copyToClipboard(`${record.addr}|${record.port}|${record.info.username === undefined ? record.info.method : record.info.username}|${record.info.password}`)}
                            size="small"
                        />
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: '账号',
            key: 'username',
            width: 100,
            render: (_, record) => (
                    <span>{record.info.username === undefined ? record.info.method : record.info.username}</span>
            ),
        },
        {
            title: '密码',
            key: 'password',
            width: 100,
            render: (_, record) => (
                    <span>{record.info.password }</span>
            ),
        },
        {
            title: '可换IP',
            dataIndex: 'jumpCount',
            key: 'jumpCount',
            width: 80,
        },
        {
            title: '地区',
            dataIndex: 'city',
            key: 'city',
            width: 200,
        },
        {
            title: '到期时间',
            dataIndex: 'deleteTime',
            key: 'deleteTime',
            width: 180,
        },
    ];

    // 添加搜索处理函数
    const handleSearch = () => {
        const params = {
            current: 1,
            pageSize: pagination.pageSize,
            ...filters,
        };
        getProxyList(params);
    };

    // 修改 getProxyList 以包含筛选条件
    const getProxyList = async (params) => {
        try {
            setLoading(true);
            const response = await authAPI.getMyProxyList({
                page: params.current,
                pageSize: params.pageSize,
                gameName: params.gameName,
                addrString: params.addrString,
                port: params.port ? parseInt(params.port, 10) || undefined : undefined,
                deleteTime: params.deleteTime && params.deleteTime.length === 2 ? [
                    params.deleteTime[0].format('YYYY-MM-DD HH:mm:ss'),
                    params.deleteTime[1].format('YYYY-MM-DD HH:mm:ss')
                ] : undefined,
            });

            setData((response?.data?.list || []).map(item => ({
                ...item,
                key: item.id,
            })));
            
            setPagination(prev => ({
                ...prev,
                current: params.current,
                total: response?.data?.totalCount || 0,
            }));
        } catch (error) {
            message.error('获取代理列表失败：' + error.message);
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    const handleTableChange = (newPagination) => {
        const params = {
            current: newPagination.current,
            pageSize: newPagination.pageSize,
            ...filters,
        };
        getProxyList(params);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // 添加操作按钮的处理函数
    const handleExport = () => {
        // 获取选中的代理数据
        const selectedProxies = data.filter(item => selectedRowKeys.includes(item.key));
        
        // 格式化代理信息
        const content = selectedProxies.map(proxy => 
            `${proxy.addr}|${proxy.port}|${proxy.info.username === undefined ? proxy.info.method : proxy.info.username}|${proxy.info.password}`
        ).join('\n');
        
        // 建 Blob 对象
        const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
        
        // 创建下载链接
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `proxy_list_${new Date().getTime()}.txt`; // 文件时间戳
        
        // 触发下载
        document.body.appendChild(link);
        link.click();
        
        // 清理
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        
        message.success(`成功导出 ${selectedProxies.length} 个代理信息`);
    };

    // 处理续费按钮点击
    const handleRenew = () => {
        setIsRenewModalVisible(true);
    };

    // 处理修改按钮点击
    const handleModify = () => {
        if (selectedRowKeys.length === 0) {
            message.warning('请选择要修改的代理');
            return;
        }
        setIsModifyModalVisible(true);
    };

    const fetchAvailableAddresses = async () => {
        try {
            const response = await authAPI.getAddrAll();
            if (response.code === 0) {
                availableAddresses = response.data.list || [];
            }
        } catch (error) {
            message.error('获取地址列表失败：' + error.message);
        }
    };

    const fetchAvailableGames = async () => {
        try {
            const response = await authAPI.getGameList();
            if (response.code === 0) {
                availableGames = response.data.list || [];
            }
        } catch (error) {
            message.error('获取游戏列表失败：' + error.message);
        }
    };

    // 添加一个初始化函数
    const initData = () => {
        const params = {
            current: 1,
            pageSize: 10,
            gameName: '',
            addrString: '',
            port: '',
            deleteTime: [],
        };
        getProxyList(params);
    };

    // 修改 useEffect，在组件挂载时调用初始化函数
    useEffect(() => {
        initData();
        fetchAvailableAddresses();
        fetchAvailableGames();
    }, []);

    // 添加缺失的处理函数
    const handleSwitchGame = () => {
        setIsSwitchGameModalVisible(true);
    };

    const handleSwitchIP = () => {
        setIsSwitchIPModalVisible(true);
    };

    // 修改重置按钮的处理函数
    const handleReset = () => {
        // 定义重置后的过滤器值
        const resetFilters = {
            gameName: '',
            addrString: '',
            port: '',
            deleteTime: [],
        };
        
        // 更新过滤器状态
        setFilters(resetFilters);
        
        // 立即使用重置后的值进行搜索
        const params = {
            current: 1,
            pageSize: pagination.pageSize,
            ...resetFilters,
        };
        getProxyList(params);
    };

    return (
        <div className={styles.container}>
            <div className={styles.tableHeader}>
                <Space size="middle" direction="vertical" style={{ width: '100%' }}>
                    <Space size="middle" wrap>
                        <Input
                            placeholder="游戏名称"
                            value={filters.gameName}
                            onChange={e => setFilters(prev => ({ ...prev, gameName: e.target.value }))}
                            style={{ width: '100%', minWidth: 120, maxWidth: 200 }}
                        />
                        <Input
                            placeholder="IP地址"
                            value={filters.addrString}
                            onChange={e => setFilters(prev => ({ ...prev, addrString: e.target.value }))}
                            style={{ width: '100%', minWidth: 120, maxWidth: 200 }}
                        />
                        <Input
                            placeholder="端口"
                            value={filters.port}
                            onChange={e => setFilters(prev => ({ ...prev, port: e.target.value }))}
                            style={{ width: '100%', minWidth: 80, maxWidth: 120 }}
                        />
                        <RangePicker
                            showTime
                            value={filters.deleteTime}
                            onChange={dates => setFilters(prev => ({ ...prev, deleteTime: dates }))}
                            style={{ width: '100%', minWidth: 280, maxWidth: 380 }}
                        />
                        <Space>
                            <Button type="primary" onClick={handleSearch}>
                                搜索
                            </Button>
                            <Button onClick={handleReset}>
                                重置
                            </Button>
                        </Space>
                    </Space>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Space wrap>
                            <Button 
                                type="primary" 
                                disabled={selectedRowKeys.length === 0}
                                onClick={handleExport}
                            >
                                导出代理
                            </Button>
                            <Button 
                                disabled={selectedRowKeys.length === 0}
                                onClick={handleRenew}
                            >
                                续费
                            </Button>
                            <Button 
                                disabled={selectedRowKeys.length === 0}
                                onClick={handleSwitchGame}
                            >
                                切换游戏
                            </Button>
                            <Button 
                                disabled={selectedRowKeys.length === 0}
                                onClick={handleSwitchIP}
                            >
                                切换IP
                            </Button>
                            <Button 
                                disabled={selectedRowKeys.length === 0}
                                onClick={handleModify}
                            >
                                修改(协议/账号/密码)
                            </Button>
                        </Space>
                        {selectedRowKeys.length > 0 && (
                            <span>已选择 {selectedRowKeys.length} 项</span>
                        )}
                    </div>
                </Space>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                rowSelection={rowSelection}
                pagination={pagination}
                onChange={handleTableChange}
                loading={loading}
                scroll={{ x: 'max-content' }}
            />

            {/* 续费 Modal */}
            <RenewModal
                visible={isRenewModalVisible}
                onCancel={() => setIsRenewModalVisible(false)}
                selectedRowKeys={selectedRowKeys}
                onSuccess={() => {
                    setIsRenewModalVisible(false);
                    getProxyList(pagination);
                }}
            />

            {/* 切换游戏 Modal */}
            <SwitchGameModal
                visible={isSwitchGameModalVisible}
                onCancel={() => setIsSwitchGameModalVisible(false)}
                selectedRowKeys={selectedRowKeys}
                onSuccess={() => {
                    setIsSwitchGameModalVisible(false);
                    getProxyList(pagination);
                }}
                availableGames={availableGames}
            />

            {/* 修改代理 Modal */}
            <ModifyProxyModal
                visible={isModifyModalVisible}
                onCancel={() => setIsModifyModalVisible(false)}
                selectedRowKeys={selectedRowKeys}
                onSuccess={() => {
                    setIsModifyModalVisible(false);
                    getProxyList(pagination);
                }}
            />

            {/* 切换IP Modal */}
            <SwitchIPModal
                visible={isSwitchIPModalVisible}
                onCancel={() => setIsSwitchIPModalVisible(false)}
                selectedRowKeys={selectedRowKeys}
                onSuccess={() => {
                    setIsSwitchIPModalVisible(false);
                    getProxyList(pagination);
                }}
                availableAddresses={availableAddresses}
            />
        </div>
    );
};

export default ProxyPage; 