import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Radio, Typography, Space, Spin, message } from 'antd';
import { authAPI } from '@/api';

const { Text } = Typography;

const DAYS_OPTIONS = [
    { label: '7天', value: 'day7' },
    { label: '30天', value: 'day30' },
    { label: '90天', value: 'day90' },
    { label: '180天', value: 'day180' },
    { label: '360天', value: 'day360' },
];

const RenewModal = ({ 
    visible, 
    onCancel, 
    selectedRowKeys, 
    onSuccess 
}) => {
    const [renewDays, setRenewDays] = useState('day7');
    const [renewPrice, setRenewPrice] = useState(0);
    const [priceLoading, setPriceLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('balance'); // 'balance' 或 'points'
    const [accountBalance, setAccountBalance] = useState({
        balance: 0,
        points: 0
    });

    // 查询续费价格
    const queryRenewPrice = useCallback(async (days) => {
        setPriceLoading(true);
        try {
            const data = await authAPI.getRenewPrice({
                id: selectedRowKeys,
                day: days,
            });

            if (data.code === 0) {
                const totalPrice = data.data.list.reduce((sum, item) => sum + item.money, 0);
                setRenewPrice(totalPrice);
            } else {
                message.error('获取价格失败');
            }
        } catch (error) {
            message.error('查询价格错误: ' + error.message);
        } finally {
            setPriceLoading(false);
        }
    }, [selectedRowKeys]);

    // 获取账户余额和积分
    const fetchAccountBalance = async () => {
        try {
            const data = await authAPI.getMyAccount();
            if (data.code === 0) {
                setAccountBalance({
                    balance: data.data.money,
                    points: data.data.integral
                });
            }
        } catch (error) {
            message.error('获取账户余额失败: ' + error.message);
        }
    };

    // 处理天数选择变化
    const handleDaysChange = (e) => {
        const newDays = e.target.value;
        setRenewDays(newDays);
        queryRenewPrice(newDays);
    };

    // 处理续费确认
    const handleRenewConfirm = async () => {
        try {
            let res = null;
            if (paymentMethod === 'balance') {
                // 余额支付
                res = await authAPI.renew({
                    id: selectedRowKeys,
                    day: renewDays,
                });
            } else {
                // 积分支付
                res = await authAPI.renewalIntegral({
                    id: selectedRowKeys,
                    day: renewDays,
                });
            }

            if (res.code === 0) {
                message.success('续费成功');
                onSuccess();
            } else {
                message.error('续费失败: ' + res.message);
            }
        } catch (error) {
            message.error('续费失败: ' + error.message);
        }
    };

    // Modal 显示时查询初始价格和余额
    useEffect(() => {
        if (visible) {
            queryRenewPrice(renewDays);
            fetchAccountBalance();
        } else {
            // Modal关闭时重置状态
            setRenewDays('day7');
            setRenewPrice(0);
            setPaymentMethod('balance');
        }
    }, [visible, queryRenewPrice, renewDays]);

    return (
        <Modal
            title="代理续费"
            open={visible}
            onOk={handleRenewConfirm}
            onCancel={onCancel}
            okText="确认"
            cancelText="取消"
            width={500}
            okButtonProps={{
                disabled: (
                    paymentMethod === 'balance' && renewPrice > accountBalance.balance) ||
                    (paymentMethod === 'points' && renewPrice > accountBalance.points
                )
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {/* 选择数量提示 */}
                <p>已选择 {selectedRowKeys.length} 个代理进行续费</p>
                
                {/* 续费时长选择 */}
                <div>
                    <Text strong>续费时长：</Text>
                    <Radio.Group 
                        options={DAYS_OPTIONS} 
                        value={renewDays}
                        onChange={handleDaysChange}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </div>

                {/* 支付方式选择 */}
                <div>
                    <Text strong>支付方式：</Text>
                    <Radio.Group 
                        value={paymentMethod}
                        onChange={e => setPaymentMethod(e.target.value)}
                        style={{ marginTop: '8px' }}
                    >
                        <Space direction="vertical">
                            <Radio value="balance">
                                <Space>
                                    <span>余额支付</span>
                                    <Text type="secondary">
                                        (当前余额: ¥{accountBalance.balance.toFixed(2)})
                                    </Text>
                                </Space>
                            </Radio>
                            <Radio value="points">
                                <Space>
                                    <span>积分支付</span>
                                    <Text type="secondary">
                                        (当前积分: {accountBalance.points})
                                    </Text>
                                </Space>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </div>

                {/* 价格显示 */}
                <div style={{ 
                    padding: '12px',
                    background: '#f5f5f5',
                    borderRadius: '4px'
                }}>
                    <Space size="large">
                        <Text strong>应付金额：</Text>
                        {priceLoading ? (
                            <Spin size="small" />
                        ) : (
                            <Text style={{ fontSize: '16px', color: '#ff4d4f', fontWeight: 'bold' }}>
                                {paymentMethod === 'balance' 
                                    ? `¥ ${renewPrice.toFixed(2)}` 
                                    : `${Number(renewPrice).toFixed(2)} 积分`}
                            </Text>
                        )}
                    </Space>
                </div>

                {/* 余额不足提示 */}
                {paymentMethod === 'balance' && renewPrice > accountBalance.balance && (
                    <Text type="danger">余额不足，请先充值</Text>
                )}
                {paymentMethod === 'points' && renewPrice > accountBalance.points && (
                    <Text type="danger">积分不足，请先充值</Text>
                )}
            </div>
        </Modal>
    );
};

export default RenewModal; 