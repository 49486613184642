import React, { useState, useEffect  } from 'react';
import { Modal, message } from 'antd';
import { authAPI } from '@/api';
import './TrialConfirmModal.css';

const TrialConfirmModal = ({
    visible,
    onCancel,
    onSuccess,
    orderInfo,
}) => {
    const [loading, setLoading] = useState(false);

    const [testCount, setTestCount] = useState(0);
    useEffect(() => {
        const fetchUser= async () => {
            try {
                const response = await authAPI.getMyAccount();
                setTestCount(response.data.testCount);
            } catch (error) {
                message.error('获取试用次数失败，请刷新重试');
            }
        };

        if (visible) {
            fetchUser();
        }
    }, [visible]);


    const handleConfirmTrial = async () => {

        if(testCount <= orderInfo?.addrId?.length){
            message.error('试用次数不足');
            return;
        }

        setLoading(true);
        try {
            const response = await authAPI.trialServer(orderInfo);
            message.success('申请试用成功'+response.data.list.length+'个代理');
            onSuccess?.(response.data);
            onCancel();
        } catch (error) {
            const errorMsg = error.response?.data?.msg 
                || error.message 
                || '申请试用失败，请重试';
            message.error(errorMsg);
            console.error('试用失败:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="确认试用"
            open={visible}
            onCancel={onCancel}
            onOk={handleConfirmTrial}
            okText="确认试用"
            cancelText="取消"
            width={480}
            centered
            confirmLoading={loading}
            className="trial-modal"
        >
            <div className="trial-container">
                <div className="trial-info">
                    <div className="info-icon"></div>
                    <div className="info-content">
                        <div className="info-title">
                            试用 {orderInfo?.addrId?.length || 0} 个代理
                        </div>
                        <div className="info-desc">
                            当前试用次数：{testCount}
                        </div>
                    </div>
                </div>

                <div className="trial-notice">
                    <h4>试用须知</h4>
                    <ul>
                        <li>
                            <span className="dot"></span>
                            试用时长为24小时
                        </li>
                        <li>
                            <span className="dot"></span>
                            试用期间具有完整的使用权限
                        </li>
                        <li>
                            <span className="dot"></span>
                            试用到期后将自动失效
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    );
};

export default TrialConfirmModal; 