import React from 'react';
import Recharge from '@/components/account/Recharge/Recharge.js';


const RechargePage = () => {
  return (
    <Recharge />
  );
};

export default RechargePage; 