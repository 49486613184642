import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Row, Col, Statistic, Tabs, DatePicker } from 'antd';
import { UserAddOutlined, DollarOutlined, CopyOutlined } from '@ant-design/icons';
import styles from './Promotion.module.css';
import { message } from 'antd';
import { useAuth } from '@/contexts/AuthContext';
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import { authAPI } from '@/api';

const { RangePicker } = DatePicker;

const PromotionPage = () => {
  const { user } = useAuth();
  const promotionLink = `${window.location.origin}/register?ref=${user?.id}`;
  const copyLink = () => {
    navigator.clipboard.writeText(promotionLink);
    // 这里可以添加复制成功的提示
    message.success('复制成功');
  };

  // 添加分页和时间筛选状态
  const [inviteParams, setInviteParams] = useState({
    page: 1,
    pageSize: 10,
    startTime: '',
    endTime: ''
  });

  const [rewardParams, setRewardParams] = useState({
    page: 1,
    pageSize: 10,
    startTime: '',
    endTime: ''
  });

  // 获取我的推广用户
  const [inviteCount,setIntervalCount] = useState(0);
  const [inviteList, setInviteList] = useState([]);
  const getPromotionUser = async (params = inviteParams) => {
    const requestParams = {
      page: params.page,
      pageSize: params.pageSize,
      ...(params.startTime && { startTime: params.startTime }),
      ...(params.endTime && { endTime: params.endTime })
    };
    
    const res = await authAPI.getPromotionUser(requestParams);
    if (res.code === 0) {
      setInviteList(res.data.list);
      setIntervalCount(res.data.totalCount);
      return res.data;
    }
  };

  // 获取我的分成记录
  const [rewardList, setRewardList] = useState([]);
  const getRewardList = async (params = rewardParams) => {
    const requestParams = {
      page: params.page,
      pageSize: params.pageSize,
      ...(params.startTime && { startTime: params.startTime }),
      ...(params.endTime && { endTime: params.endTime })
    };

    const res = await authAPI.getRewardList(requestParams);
    if (res.code === 0) {
      setRewardList(res.data.list);
      return res.data;
    }
  };

  // 处理邀请记录分页变化
  const handleInvitePageChange = (page, pageSize) => {
    const newParams = { ...inviteParams, page, pageSize };
    setInviteParams(newParams);
    getPromotionUser(newParams);
  };

  // 处理返利记录分页变化
  const handleRewardPageChange = (page, pageSize) => {
    const newParams = { ...rewardParams, page, pageSize };
    setRewardParams(newParams);
    getRewardList(newParams);
  };

  // 处理时间范围变化
  const handleInviteTimeChange = (dates) => {
    if (!dates) {
      const newParams = {
        ...inviteParams,
        page: 1,
        startTime: '',
        endTime: ''
      };
      setInviteParams(newParams);
      getPromotionUser(newParams);
    } else {
      const [start, end] = dates;
      const newParams = {
        ...inviteParams,
        page: 1,
        startTime: start.format('YYYY-MM-DD HH:mm:ss'),
        endTime: end.format('YYYY-MM-DD HH:mm:ss'),
      };
      setInviteParams(newParams);
      getPromotionUser(newParams);
    }
  };

  const handleRewardTimeChange = (dates) => {
    if (!dates) {
      const newParams = {
        ...rewardParams,
        page: 1,
        startTime: '',
        endTime: ''
      };
      setRewardParams(newParams);
      getRewardList(newParams);
    } else {
      const [start, end] = dates;
      const newParams = {
        ...rewardParams,
        page: 1,
        startTime: start.format('YYYY-MM-DD HH:mm:ss'),
        endTime: end.format('YYYY-MM-DD HH:mm:ss'),
      };
      setRewardParams(newParams);
      getRewardList(newParams);
    }
  };

  // 初始化数据
  useEffect(() => {
    getPromotionUser();
    getRewardList();
  }, []);

  // 邀请记录表格列配置
  const inviteColumns = [
    { 
      title: '用户名', 
      dataIndex: 'username',
      key: 'username' 
    },
    { 
      title: '注册时间', 
      dataIndex: 'createdAt',
      key: 'createdAt' 
    }
  ];

  // 返利记录表格列配置
  const rewardColumns = [
    { 
      title: '标题', 
      dataIndex: 'title',
      key: 'title' 
    },
    { 
      title: '返利时间', 
      dataIndex: 'createdAt',
      key: 'createdAt' 
    },
    { 
      title: '充值金额', 
      dataIndex: 'czMoney',
      key: 'czMoney' 
    },
    { 
      title: '返利积分', 
      dataIndex: 'fcIntegral',
      key: 'fcIntegral'
    },
    { 
      title: '来源用户', 
      dataIndex: 'czId',
      key: 'czId' 
    }
  ];

  // 更新 tabItems
  const tabItems = [
    {
      key: 'invites',
      label: '邀请记录',
      children: (
        <>
          <div style={{ marginBottom: 16 }}>
            <RangePicker
              locale={locale}
              onChange={handleInviteTimeChange}
            />
          </div>
          <Table 
            columns={inviteColumns}
            dataSource={inviteList}
            pagination={{
              current: inviteParams.page,
              pageSize: inviteParams.pageSize,
              onChange: handleInvitePageChange,
              total: inviteList?.totalCount || 0,
              showTotal: (total) => `共 ${total} 条记录`
            }}
          />
        </>
      ),
    },
    {
      key: 'rewards',
      label: '返利记录',
      children: (
        <>
          <div style={{ marginBottom: 16 }}>
            <RangePicker
              locale={locale}
              onChange={handleRewardTimeChange}
            />
          </div>
          <Table 
            columns={rewardColumns}
            dataSource={rewardList}
            pagination={{
              current: rewardParams.page,
              pageSize: rewardParams.pageSize,
              onChange: handleRewardPageChange,
              total: rewardList?.totalCount || 0,
              showTotal: (total) => `共 ${total} 条记录`
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      {/* 推广概览卡片 */}
      <Card className={styles.overviewCard}>
        <div className={styles.overviewHeader}>
          <div className={styles.overviewTitle}>
            <h2>推广返利</h2>
            <p>邀请好友注册，可一直获得他的返利奖励</p>
          </div>
          <Button 
            type="primary" 
            size="large"
            icon={<CopyOutlined />}
            onClick={copyLink}
            className={styles.copyButton}
          >
            复制推广链接
          </Button>
        </div>
        
        <Row gutter={[24, 24]} className={styles.statsRow}>
          <Col xs={24} sm={24} md={8}>
            <div className={styles.statsCard}>
              <Statistic 
                title="累计邀请人数"
                value={inviteCount}
                prefix={<UserAddOutlined />}
                className={styles.statistic}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className={styles.statsCard}>
              <Statistic 
                title="累计获得返利"
                value="*"
                prefix={<DollarOutlined />}
                suffix="元"
                className={styles.statistic}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className={styles.statsCard}>
              <div className={styles.promotionLink}>
                <div className={styles.linkTitle}>推广链接</div>
                <div className={styles.link}>{promotionLink}</div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>

      {/* 记录表格卡片 */}
      <Card className={styles.recordsCard}>
        <Tabs 
          items={tabItems}
          className={styles.tabs}
        />
      </Card>
    </div>
  );
};

export default PromotionPage; 