import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, message, Modal } from 'antd';
import { publicAPI } from '@/api';
import { useNavigate } from 'react-router-dom';
import { LockOutlined, MobileOutlined } from '@ant-design/icons';
import '@/styles/ResetPassword.css';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [showCaptcha, setShowCaptcha] = useState(false);
    const [captchaInfo, setCaptchaInfo] = useState({ cid: '', image: '' });
    const [captchaCode, setCaptchaCode] = useState('');

    // 倒计时
    React.useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown(c => c - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [countdown]);

    // 获取图形验证码
    const getCaptcha = async () => {
        try {
            const res = await publicAPI.getCaptcha();
            if (res.code === 0) {
                setCaptchaInfo({
                    cid: res.data.cid,
                    image: res.data.base64
                });
            }
        } catch (error) {
            message.error('获取验证码失败');
        }
    };

    // 发送短信验证码
    const handleSendCode = async () => {
        try {
            const mobile = form.getFieldValue('mobile');
            if (!mobile) {
                message.error('请输入手机号');
                return;
            }
            if (!/^1[3-9]\d{9}$/.test(mobile)) {
                message.error('请输入正确的手机号');
                return;
            }

            setShowCaptcha(true);
            getCaptcha();
        } catch (error) {
            message.error('发送失败，请重试');
        }
    };

    // 确认图形验证码并发送短信
    const handleCaptchaConfirm = async () => {
        if (!captchaCode) {
            message.error('请输入图形验证码');
            return;
        }

        try {
            const mobile = form.getFieldValue('mobile');
            const res = await publicAPI.smsSend({
                mobile,
                event: 'resetPwd',
                cid: captchaInfo.cid,
                code: captchaCode
            });

            if (res.code === 0) {
                setShowCaptcha(false);
                setCaptchaCode('');
                setCountdown(60);
                message.success('验证码已发送');
            } else {
                message.error(res.message || '发送失败');
                getCaptcha();
            }
        } catch (error) {
            message.error('发送失败，请重试');
            getCaptcha();
        }
    };

    const onFinish = async (values) => {
        if (values.password !== values.confirmPassword) {
            message.error('两次密码输入不一致');
            return;
        }
        
        setLoading(true);
        try {
            const res = await publicAPI.resetPassword({
                mobile: values.mobile,
                password: values.password,
                code: values.code
            });
            
            if (res.code === 0) {
                message.success('密码重置成功');
                navigate('/login');
            } else {
                message.error(res.message || '重置失败，请重试');
            }
        } catch (error) {
            message.error('服务异常，请稍后重试');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="page-container">
            <div className="page-content">
                <div className="page-header">
                    <div className="page-title">找回密码</div>
                    <div className="page-desc">重置您的账户密码</div>
                </div>

                <Form
                    form={form}
                    name="resetPassword"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        name="mobile"
                        rules={[
                            { required: true, message: '请输入手机号' },
                            { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号' }
                        ]}
                    >
                        <Input
                            prefix={<MobileOutlined />}
                            placeholder="请输入手机号"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="code"
                        rules={[
                            { required: true, message: '请输入验证码' },
                            { len: 4, message: '验证码为4位数字' }
                        ]}
                    >
                        <Input
                            placeholder="请输入验证码"
                            size="large"
                            suffix={
                                <Button
                                    type="link"
                                    disabled={countdown > 0}
                                    onClick={handleSendCode}
                                >
                                    {countdown > 0 ? `${countdown}秒后重试` : '获取验证码'}
                                </Button>
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: '请输入新密码' },
                            { min: 6, message: '密码不能少于6个字符' }
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder="请输入新密码"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        name="confirmPassword"
                        rules={[
                            { required: true, message: '请确认新密码' },
                            { min: 6, message: '密码不能少于6个字符' }
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            placeholder="请确认新密码"
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                            loading={loading}
                        >
                            重置密码
                        </Button>
                    </Form.Item>

                    <div className="form-footer">
                        <span>想起密码了？</span>
                        <Link to="/login">立即登录</Link>
                    </div>
                </Form>

                <Modal
                    title="请完成安全验证"
                    open={showCaptcha}
                    onCancel={() => setShowCaptcha(false)}
                    footer={[
                        <Button key="cancel" onClick={() => setShowCaptcha(false)}>
                            取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleCaptchaConfirm}>
                            确定
                        </Button>
                    ]}
                >
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ marginBottom: 16 }}>
                            <img
                                src={captchaInfo.image}
                                alt="验证码"
                                style={{ cursor: 'pointer' }}
                                onClick={getCaptcha}
                            />
                        </div>
                        <Input
                            placeholder="请输入图形验证码"
                            value={captchaCode}
                            onChange={e => setCaptchaCode(e.target.value)}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ResetPassword; 