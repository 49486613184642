import request from './request';

export const authAPI = {
  // 获取我的账户信息
  getMyAccount: () => {
    return request({
      url: '/account/index',
      method: 'get',
    });
  },
  // 退出登录
  logout: () => {
    return request({
      url: '/account/logout',
      method: 'post',
    });
  },
  // 获取支持的支付方式
  getPayInfo: () => {
    return request({
      url: '/account/order/getPayInfo',
      method: 'get',
    });
  },
  // 创建充值订单
  createOrder: (data) => {
    return request({
      url: '/account/order/createOrder',
      method: 'post',
      data,
    });
  },

  // 获取充值订单列表
  getOrderList: (data) => {
    return request({
      url: '/account/order/index',
      method: 'post',
      data,
    });
  },

  // 获取充值赠送列表
  getGiftList: (data) => {
    return request({
      url: '/account/order/giffLog',
      method: 'post',
      data,
    });
  },

  // 获取购买记录列表
  getBuyLog: (data) => {
    return request({
      url: '/account/proxy_order/index',
      method: 'post',
      data,
    });
  },

  // 修改密码
  changePassword: (data) => {
    return request({
      url: '/account/set/changePassword',
      method: 'post',
      data,
    });
  },

  // 实名认证
  nameAuth: (data) => {
    return request({
      url: '/account/set/nameAuth',
      method: 'post',
      data,
    });
  },
  // 获取我的推广分成记录
  getTgfcList: (data) => {
    return request({
      url: '/account/tgfc/index',
      method: 'post',
      data,
    });
  },
  // 获取我的代理列表
  getMyProxyList: (data) => {
    return request({
      url: '/account/proxy/getMyProxyList',
      method: 'post',
      data,
    });
  },
  // 查询续费价格
  getRenewPrice: (data) => {
    return request({
      url: '/account/proxy/renewalInquiry',
      method: 'post',
      data,
    });
  },
  // 余额续费
  renewal: (data) => {
    return request({
      url: '/account/proxy/renewal',
      method: 'post',
      data,
    });
  },
  // 积分续费
  renewalIntegral: (data) => {
    return request({
      url: '/account/proxy/renewalIntegral',
      method: 'post',
      data,
    });
  }, 
  // 查询可切换的游戏
  jumpGameQuery: (data) => {
    return request({
      url: '/account/proxy/jumpGameQuery',
      method: 'post',
      data,
    });
  },
  // 切换游戏
  jumpGame: (data) => {
    return request({
      url: '/account/proxy/jumpGame',
      method: 'post',
      data,
    });
  },
  // 查询可切换的IP
  jumpAddrQuery: (data) => {
    return request({
      url: '/account/proxy/jumpAddrQuery',
      method: 'post',
      data,
    });
  },
  // 切换IP
  jumpAddr: (data) => {
    return request({
      url: '/account/proxy/jumpAddr',
      method: 'post',
      data,
    });
  },
  // 获取所有ip地址
  getAddrAll: (data) => {
    return request({
      url: '/account/proxy/getAddrAll',
      method: 'post',
      data,
    });
  },
  // 编辑代理
  editProxy: (data) => {
    return request({
      url: '/account/proxy/editProxy',
      method: 'post',
      data,
    });
  },
  // 获取所有游戏
  getGameList: () => {
    return request({
      url: '/account/mall/getGameList',
      method: 'get',
    });
  },
  // 获取游戏列表
  getGameList: () => {
    return request({
      url: '/account/mall/getGameList',
      method: 'get',
    });
  },
  // 获取价格体系
  getPriceList: () => {
    return request({
      url: '/account/mall/getPriceList',
      method: 'get',
    });
  },
  // 获取城市列表
  getCityList: (data) => {
    return request({
      url: '/account/mall/getCityList',
      method: 'post',
      data,
    });
  },
  // 获取服务器列表
  getServerList: (data) => {
    return request({
      url: '/account/mall/getServerList',
      method: 'post',
      data,
    });
  },

  // 积分购买
  integralBuyServer: (data) => {
    return request({
      url: '/account/mall/integralBuyServer',
      method: 'post',
      data,
    });
  },
  // 余额购买
  moneyBuyServer: (data) => {
    return request({
      url: '/account/mall/moneyBuyServer',
      method: 'post',
      data,
    });
  },
  // 试用
  trialServer: (data) => {
    return request({
      url: '/account/mall/testServer',
      method: 'post',
      data,
    });
  },
  // 获取协议信息
  getProtocolInfo: () => {
    return request({
      url: '/account/mall/getProtocolInfo',
      method: 'get',
    });
  },
  // 获取我的推广用户
  getPromotionUser: (data) => {
    return request({
      url: '/account/tgfc/dataList',
      method: 'post',
      data,
    });
  },
  // 获取我的分成记录
  getRewardList: (data) => {
    return request({
      url: '/account/tgfc/index',
      method: 'post',
      data,
    });
  },

}; 