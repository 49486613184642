import React, { createContext, useState, useContext, useEffect } from 'react';
import { message } from 'antd';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    // 检查 token 是否过期
    const isTokenExpired = (expiresAt) => {
        if (!expiresAt) return true;
        return new Date().getTime() > expiresAt;
    };

    // 初始化时检查登录状态
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        const storedToken = localStorage.getItem('token');
        const expiresAt = localStorage.getItem('expiresAt');
        
        if (storedUser && storedToken && expiresAt) {
            if (isTokenExpired(parseInt(expiresAt))) {
                logout('登录已过期，请重新登录');
            } else {
                const userData = JSON.parse(storedUser);
                setUser(userData);
                setIsAuthenticated(true);
            }
        }
    }, []);

    const login = (responseData) => {
        const { data } = responseData;
        // 计算过期时间：当前时间 + 后端返回的过期秒数
        const expiresAt = new Date().getTime() + (data.expires * 1000);
        
        const userData = {
            id: data.id,
            username: data.username,
            // ... 其他用户信息
        };

        // 保存认证信息
        localStorage.setItem('token', data.token);
        localStorage.setItem('user', JSON.stringify(userData));
        localStorage.setItem('expiresAt', expiresAt.toString());
        
        setUser(userData);
        setIsAuthenticated(true);
    };

    const logout = (msg = '已退出登录') => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('expiresAt');
        
        setUser(null);
        setIsAuthenticated(false);

        if (msg) {
            message.info(msg);
        }
    };

    const checkAuth = () => {
        const expiresAt = localStorage.getItem('expiresAt');
        if (expiresAt && isTokenExpired(parseInt(expiresAt))) {
            logout('登录已过期，请重新登录');
            return false;
        }
        return isAuthenticated;
    };

    return (
        <AuthContext.Provider value={{ 
            user, 
            isAuthenticated,
            login, 
            logout,
            checkAuth
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}; 