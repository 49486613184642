import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Table, Typography, Select, message } from 'antd';
import { authAPI } from '@/api';

const { Text } = Typography;

const SwitchGameModal = ({ 
    visible, 
    onCancel, 
    selectedRowKeys, 
    onSuccess,
    availableGames 
}) => {
    const [gameList, setGameList] = useState([]);
    const [switchGameLoading, setSwitchGameLoading] = useState(false);

    const columns = [
        {
            title: '代理ID',
            dataIndex: 'proxyId',
            key: 'proxyId',
            width: 120,
        },
        {
            title: '当前游戏',
            dataIndex: 'gameName',
            key: 'gameName',
            width: 200,
        },
        {
            title: '切换到',
            dataIndex: 'targetGame',
            key: 'targetGame',
            width: 300,
            render: (_, record) => {
                return (
                    <Select
                        style={{ width: '100%' }}
                        placeholder="请选择游戏"
                        value={record.targetGameId}
                        onChange={(value) => handleGameChange(record.proxyId, record.orgGameId, value)}
                        options={record.gameSelect}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        allowClear
                    />
                );
            }
        }
    ];

    const handleGameChange = (proxyId, orgGameId, gameId) => {
        const newGameList = gameList.map(item => {
            if (item.proxyId === proxyId) {
                // 更新当前代理的选择
                return { ...item, targetGameId: gameId };
            }

            if (item.orgGameId === orgGameId) {
                const updatedGameSelect = item.gameSelect.map(game => {
                    if (gameId === null || gameId === undefined) {
                        // 如果取消选择，恢复所有选项
                        return { ...game, disabled: false };
                    } else {
                        // 否则，禁用相同 gameId 的选项
                        return { ...game, disabled: game.value === gameId };
                    }
                });
                return { ...item, gameSelect: updatedGameSelect };
            }

            return item;
        });

        setGameList(newGameList);
    };

    const fetchGameList = useCallback(async () => {
        try {
            setSwitchGameLoading(true);
            const proxyResponse = await authAPI.jumpGameQuery({
                id: selectedRowKeys,
            }); 
            const list = proxyResponse.data.list || [];

            if (list.length === 0 || list.every(item => item.gameIds.length === 0)) {
                message.warning('当前代理没有可切换的游戏');
            } else {
                setGameList(list.map(item => ({
                    ...item,
                    targetGameId: undefined,
                    gameSelect: availableGames.map(game => ({
                        label: game.name,
                        value: game.id
                    })),
                    disabledGameIds: []
                })));
            }
        } catch (error) {
            message.error('获取游戏列表失败：' + error.message);
        } finally {
            setSwitchGameLoading(false);
        }
    }, [selectedRowKeys, availableGames]);

    useEffect(() => {
        if (visible) {
            fetchGameList();
        } else {
            setGameList([]);
        }
    }, [visible, fetchGameList]);

    const handleSwitchGameConfirm = async () => {
        const switchRequests = gameList
            .filter(item => item.targetGameId)
            .map(item => ({
                proxyId: item.proxyId,
                gameId: item.targetGameId
            }));

        if (switchRequests.length === 0) {
            message.warning('请至少选择一个要切换的游戏');
            return;
        }

        try {
            setSwitchGameLoading(true);
            const response = await authAPI.jumpGame({
                data: switchRequests
            });

            if (response.code === 0) {
                message.success('切换游戏成功');
                onSuccess();
            } else {
                message.error('切换游戏失败：' + response.message);
            }
        } catch (error) {
            message.error('切换游戏失败：' + error.message);
        } finally {
            setSwitchGameLoading(false);
        }
    };

    return (
        <Modal
            title="切换游戏"
            open={visible}
            onOk={handleSwitchGameConfirm}
            onCancel={onCancel}
            okText="确认"
            cancelText="取消"
            width={800}
        >
            <div style={{ marginBottom: 16 }}>
                <Text strong>已选择 {selectedRowKeys.length} 个代理进行游戏切换</Text>
            </div>
            <Table
                columns={columns}
                dataSource={gameList}
                loading={switchGameLoading}
                pagination={false}
                size="small"
                scroll={{ y: 400 }}
            />
        </Modal>
    );
};

export default SwitchGameModal; 