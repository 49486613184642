import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { GiftOutlined, RightOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './RechargeActivity.css';
import { publicAPI } from '@/api';

const RechargeActivity = () => {
    const navigate = useNavigate();
    
    const [bonusPlans, setBonusPlans] = useState([]);
    useEffect(() => {
        publicAPI.getGiffList().then(res => {
            setBonusPlans(res.data.list);
        });
    }, []);


    return (
        <div className="recharge-activity-page">
            <div className="animated-background">
                <div className="light-beam"></div>
                <div className="particles"></div>
            </div>

            <div className="activity-content">
                <div className="hero-section">
                    <ThunderboltOutlined className="hero-icon" />
                    <h1>充值赠送活动</h1>
                    <div className="hero-subtitle">
                        <span className="highlight">充值对应金额</span>
                        <span className="divider"></span>
                        <span className="highlight">即可获得积分</span>
                    </div>
                </div>

                <div className="plans-container">
                    {bonusPlans.map((plan, index) => (
                        <div key={index} className="plan-card" data-aos="fade-up" data-aos-delay={index * 100}>
                            <div className="plan-tag">{plan.title}</div>
                            <div className="plan-content">
                                <div className="plan-amount">
                                    <span className="currency">¥</span>
                                    <span className="number">{plan.payMoney}</span>
                                </div>
                                
                                <div className="bonus-arrow">
                                    <GiftOutlined className="gift-icon" />
                                </div>

                                <div className="bonus-amount" style={{ marginTop: 20 }}>
                                    <div className="bonus-value">{plan.gifftIntegral}</div>
                                    <div className="bonus-label">积分</div>
                                </div>
                            </div>
                            
                            <div className="card-glow"></div>
                        </div>
                    ))}
                </div>

                <div className="action-section">
                    <Button 
                        type="primary"
                        size="large"
                        onClick={() => navigate('/account/recharge')}
                        className="recharge-button"
                    >
                        立即充值 <RightOutlined />
                    </Button>
                </div>

                <div className="rules-section">
                    <div className="rules-card">
                        <h3>活动规则</h3>
                        <div className="rules-grid">
                            <div className="rule-item">
                                <div className="rule-number">01</div>
                                <div className="rule-text">积分和金币的比例为1:1</div>
                            </div>
                            <div className="rule-item">
                                <div className="rule-number">02</div>
                                <div className="rule-text">赠送积分可用于购买任意商品</div>
                            </div>
                            <div className="rule-item">
                                <div className="rule-number">03</div>
                                <div className="rule-text">活动长期有效，如有变动另行通知</div>
                            </div>
                            <div className="rule-item">
                                <div className="rule-number">04</div>
                                <div className="rule-text">赠送积分实时到账，可立即使用</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RechargeActivity; 