import React, { useState, useEffect } from 'react';
import { Modal, Radio, message } from 'antd';
import { authAPI } from '@/api';
import './PaymentConfirmModal.css';

const PaymentConfirmModal = ({
    visible,
    onCancel,
    onSuccess, // 支付成功的回调
    totalAmount,
    orderInfo, // 订单信息
}) => {
    const [paymentMethod, setPaymentMethod] = useState('balance');
    const [userBalance, setUserBalance] = useState(0);
    const [userPoints, setUserPoints] = useState(0);
    const [loading, setLoading] = useState(false);

    // 获取用户余额和积分
    useEffect(() => {
        const fetchUserBalance = async () => {
            try {
                const response = await authAPI.getMyAccount();
                setUserBalance(response.data.money);
                setUserPoints(response.data.integral);
            } catch (error) {
                console.error('获取用户余额失败:', error);
                message.error('获取余额信息失败，请刷新重试');
            }
        };

        if (visible) {
            fetchUserBalance();
        }
    }, [visible]);

    // 处理支付确认
    const handleConfirmPayment = async () => {
        // 验证余额是否足够
        if (paymentMethod === 'balance' && userBalance < totalAmount) {
            message.error('余额不足，请选择其他支付方式');
            return;
        }

        // 验证积分是否足够
        if (paymentMethod === 'points' && userPoints < totalAmount) {
            message.error('积分不足，请选择其他支付方式');
            return;
        }

        setLoading(true);
        try {
            const response = paymentMethod === 'points'
                ? await authAPI.integralBuyServer(orderInfo)
                : await authAPI.moneyBuyServer(orderInfo);

            message.success('购买成功' + response.data.list.length + '个代理');
            onSuccess?.(response.data); // 调用成功回调
            onCancel(); // 关闭弹窗

        } catch (error) {
            // 错误处理优化
            const errorMsg = error.response?.data?.msg  // 后端返回的错误信息
                || error.message  // axios 的错误信息
                || '支付失败，请重试';  // 默认错误信息
            
            message.error(errorMsg);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title="确认支付"
            open={visible}
            onCancel={onCancel}
            onOk={handleConfirmPayment}
            okText="确认支付"
            cancelText="取消"
            width={530}
            centered
            confirmLoading={loading}
            className="payment-modal"
        >
            <div className="payment-container">
                <div className="amount-section">
                    <div className="amount-label">支付金额</div>
                    <div className="amount-value">¥{totalAmount}</div>
                </div>

                <div className="payment-method">
                    <Radio.Group
                        value={paymentMethod}
                        onChange={e => setPaymentMethod(e.target.value)}
                        className="payment-radio-group"
                    >
                        <div className="method-options">
                            <div className="method-option-wrapper">
                                <Radio value="balance">
                                    <div className="option-content">
                                        <div className="info-icon balance-icon"></div>
                                        <div className="info-content">
                                            <div className="info-label">余额支付</div>
                                            <div className="info-value">¥{userBalance}</div>
                                        </div>
                                    </div>
                                </Radio>
                            </div>
                            <div className="method-option-wrapper">
                                <Radio value="points">
                                    <div className="option-content">
                                        <div className="info-icon points-icon"></div>
                                        <div className="info-content">
                                            <div className="info-label">积分支付</div>
                                            <div className="info-value">{userPoints}</div>
                                        </div>
                                    </div>
                                </Radio>
                            </div>
                        </div>
                    </Radio.Group>
                </div>
            </div>
        </Modal>
    );
};

export default PaymentConfirmModal; 