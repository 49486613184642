import React, { useState, useEffect } from 'react';
import { Modal, Select, Input, Typography, message } from 'antd';
import { authAPI } from '@/api';

const { Text } = Typography;


const CIPHER_OPTIONS = [
    { label: 'AES-128-GCM', value: 'AES-128-GCM' },
    { label: 'AES-192-GCM', value: 'AES-192-GCM' },
    { label: 'AES-256-GCM', value: 'AES-256-GCM' }
];

const ModifyProxyModal = ({ 
    visible, 
    onCancel, 
    selectedRowKeys, 
    onSuccess 
}) => {
    const [protocolInfo, setProtocolInfo] = useState([]);

    const getProtocolInfo = async () => {
        let data = [];
        const response = await authAPI.getProtocolInfo();
        if (response.data.socks) {
            data.push({ label: 'Socks5', value: 'Socks5' });
        }
        if (response.data.shadowSocks) {
            data.push({ label: 'ShadowSocks', value: 'ShadowSocks' });
        }

        setProtocolInfo(data);
    };

    useEffect(() => {
        getProtocolInfo();
    }, [visible]);

    const [modifyForm, setModifyForm] = useState({
        protocol: 'Socks5',
        username: '',
        password: ''
    });

    const handleModifyConfirm = async () => {
        try {
            if (modifyForm.username === '' || modifyForm.password === '') {
                message.warning('请输入账号和密码');
                return;
            }
            
            let data = {};
            if (modifyForm.protocol === 'ShadowSocks') {
                data = {
                    id: selectedRowKeys,
                    type: 'ss',
                    conf: {
                        method: modifyForm.username,
                        password: modifyForm.password,
                    }
                };
            } else {
                data = {
                    id: selectedRowKeys,
                    type: 'socks5',
                    conf: {
                        username: modifyForm.username,
                        password: modifyForm.password,
                    }
                };
            }

            const response = await authAPI.editProxy(data);

            if (response.code === 0) {
                message.success(response.data.msg);
                onSuccess();
                resetForm();
            } else {
                message.error('修改失败：' + response.message);
            }
        } catch (error) {
            message.error('修改失败：' + error.message);
        }
    };

    const resetForm = () => {
        setModifyForm({
            protocol: 'Socks5',
            username: '',
            password: ''
        });
    };

    const handleCancel = () => {
        resetForm();
        onCancel();
    };

    return (
        <Modal
            title="修改代理"
            open={visible}
            onOk={handleModifyConfirm}
            onCancel={handleCancel}
            okText="确认"
            cancelText="取消"
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <div>
                    <Text strong>已选择 {selectedRowKeys.length} 个代理进行修改</Text>
                </div>

                <div>
                    <Text strong>协议：</Text>
                    <Select
                        style={{ width: '100%' }}
                        value={modifyForm.protocol}
                        onChange={value => setModifyForm(prev => ({ ...prev, protocol: value }))}
                        options={protocolInfo}
                    />
                </div>

                <div>
                    <Text strong>
                        {modifyForm.protocol === 'ShadowSocks' ? '加密方式：' : '账号：'}
                    </Text>
                    {modifyForm.protocol === 'ShadowSocks' ? (
                        <Select
                            style={{ width: '100%' }}
                            value={modifyForm.username}
                            onChange={value => setModifyForm(prev => ({ ...prev, username: value }))}
                            options={CIPHER_OPTIONS}
                            placeholder="请选择加密方式"
                        />
                    ) : (
                        <Input
                            value={modifyForm.username}
                            onChange={e => setModifyForm(prev => ({ ...prev, username: e.target.value }))}
                            placeholder="请输入账号"
                        />
                    )}
                </div>

                <div>
                    <Text strong>密码：</Text>
                    <Input
                        value={modifyForm.password}
                        onChange={e => setModifyForm(prev => ({ ...prev, password: e.target.value }))}
                        placeholder="请输入密码"
                    />
                </div>
            </div>
        </Modal>
    );
};

export default ModifyProxyModal; 