import { Routes, Route, useLocation } from 'react-router-dom';
import ProtectedRoute from '@/components/account/ProtectedRoute';
import AccountLayout from '@/pages/account/layout/AccountLayout';
import Navbar from '@/components/Navbar';
import '@/App.css';
import IndexPage from '@/pages/Index';
import RechargePage from '@/pages/account/Recharge';
import ProfilePage from '@/pages/account/Profile';
import PromotionPage from '@/pages/account/Promotion';
import WalletPage from '@/pages/account/Wallet';
import SettingsPage from '@/pages/account/Settings';
import ProxyPage from '@/pages/account/Proxy';
import ProxyShopPage from '@/pages/proxy-shop';
import Footer from '@/components/Footer';
import PromotionIntro from '@/pages/promotion/PromotionIntro';
import Register from '@/pages/Register';
import ResetPassword from '@/pages/ResetPassword';

import RechargeActivity from '@/pages/recharge/RechargeActivity';

function App() {
    const location = useLocation();
    const isAccountPage = location.pathname.startsWith('/account');

    return (
        <div className="App">
            <Navbar />
            <Routes>
                <Route path="/" element={<IndexPage />} />
                <Route path="/register" element={<Register />} />
                <Route path="/proxy-shop" element={<ProxyShopPage />} />
                <Route path="/recharge" element={<RechargeActivity />} />
                <Route path="/promotion" element={<PromotionIntro />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route 
                    path="/account/*" 
                    element={
                        <ProtectedRoute>
                            <AccountLayout />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<ProfilePage />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="recharge" element={<RechargePage />} />
                    <Route path="promotion" element={<PromotionPage />} />
                    <Route path="wallet" element={<WalletPage />} />
                    <Route path="settings" element={<SettingsPage />} />
                    <Route path="proxy" element={<ProxyPage />} />
                </Route>
            </Routes>
            {!isAccountPage && <Footer />}
        </div>
    );
}

export default App;
