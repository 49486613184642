import React, { useState, useEffect, useCallback } from 'react';
import { Card, Table, Button, Row, Col, Statistic, Tabs, DatePicker, Space } from 'antd';
import { WalletOutlined, GiftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styles from './Wallet.module.css';
import { authAPI } from '@/api';

// 定义时间选择器组件
const DateRangeSelector = ({ type, value, onChange }) => (
  <Space>
    <span>时间范围：</span>
    <DatePicker.RangePicker
      value={value}
      onChange={(dates, dateStrings) => onChange(type, dates, dateStrings)}
      showTime
      format="YYYY-MM-DD HH:mm:ss"
    />
  </Space>
);

// 将 ORDER_STATUS 移到组件外部
const ORDER_STATUS = {
  ALL: -1,           // 全部状态
  NOT_PAY: 1,        // 待付款
  PAID: 2,           // 已付款
  SHIPMENTS: 3,      // 已发货
  DONE: 4,           // 已完成
  CLOSE: 5,          // 已关闭
  RETURN_REQUEST: 6, // 申请退款
  RETURNING: 7,      // 退款中
  RETURNED: 8,       // 已退款
  RETURN_REJECT: 9   // 拒绝退款
};

const WalletPage = () => {
    const navigate = useNavigate();

    // 网络获取我的账户信息
    const [accountInfo, setAccountInfo] = useState(null);
    const getAccountInfo = async () => {
        const { data } = await authAPI.getMyAccount();
        setAccountInfo(data);
    };

    // 添加数据状态
    const [rechargeData, setRechargeData] = useState([]);
    const [loading, setLoading] = useState(false);

    // 分页参数
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    // 添加时间范围状态
    const [dateRanges, setDateRanges] = useState({
        recharge: [null, null],
        consume: [null, null],
        gift: [null, null],
    });

    // 添加赠送记录状态
    const [giftData, setGiftData] = useState([]);
    const [giftLoading, setGiftLoading] = useState(false);
    const [giftPagination, setGiftPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    // 添加消费记录状态
    const [consumeData, setConsumeData] = useState([]);
    const [consumeLoading, setConsumeLoading] = useState(false);
    const [consumePagination, setConsumePagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    // 处理时间范围变化
    const handleDateRangeChange = (type, dates, dateStrings) => {
        setDateRanges(prev => ({
            ...prev,
            [type]: dates
        }));

        if (type === 'consume') {
            getConsumeRecords(1, consumePagination.pageSize, dateStrings[0], dateStrings[1]);
        }
        // TODO: 处理其他类型的记录刷新
    };

    // 获取充值记录
    const getRechargeRecords = useCallback(async (page = 1, pageSize = 10, startTime = null, endTime = null) => {
        setLoading(true);
        try {
            const params = {
                page,
                pageSize,
                ...(startTime && { startTime }),
                ...(endTime && { endTime })
            };

            const response = await authAPI.getOrderList(params);
            
            const data = Array.isArray(response.data.list) ? response.data.list : [];
            
            const dataWithKeys = data.map(item => ({
                ...item,
                key: item.id,
                money: item.money.toFixed(2),
                state: (() => {
                    switch (item.state) {
                        case ORDER_STATUS.NOT_PAY:
                            return '待付款';
                        case ORDER_STATUS.PAID:
                            return '已付款';
                        case ORDER_STATUS.SHIPMENTS:
                            return '已发货';
                        case ORDER_STATUS.DONE:
                            return '已完成';
                        case ORDER_STATUS.CLOSE:
                            return '已关闭';
                        case ORDER_STATUS.RETURN_REQUEST:
                            return '申请退款';
                        case ORDER_STATUS.RETURNING:
                            return '退款中';
                        case ORDER_STATUS.RETURNED:
                            return '已退款';
                        case ORDER_STATUS.RETURN_REJECT:
                            return '拒绝退款';
                        default:
                            return '未知状态';
                    }
                })()
            }));
            
            setRechargeData(dataWithKeys);
            setPagination(prev => ({
                ...prev,
                total: response.data.totalCount || 0,
                current: page,
                pageSize,
            }));
        } catch (error) {
            console.error('获取充值记录失败:', error);
            setRechargeData([]);
        } finally {
            setLoading(false);
        }
    }, []);

    // 获取消费记录
    const getConsumeRecords = async (page = 1, pageSize = 10, startTime = null, endTime = null) => {
        setConsumeLoading(true);
        try {
            const params = {
                page,
                pageSize,
                ...(startTime && { startTime }),
                ...(endTime && { endTime })
            };

            const response = await authAPI.getBuyLog(params);
            
            // 确保 response.data.list 是一个数组
            const data = Array.isArray(response.data.list) ? response.data.list : [];
            
            // 处理数据
            const dataWithKeys = data.map(item => ({
                ...item,
                key: item.id,
                // 确保金额和积分显示正确
                money: Number(item.money).toFixed(2),
                integral: Number(item.integral).toFixed(2)
            }));
            
            setConsumeData(dataWithKeys);
            setConsumePagination(prev => ({
                ...prev,
                total: response.data.totalCount || 0,
                current: page,
                pageSize,
            }));
        } catch (error) {
            console.error('获取消费记录失败:', error);
            setConsumeData([]);
        } finally {
            setConsumeLoading(false);
        }
    };

    // 获取赠送记录
    const getGiftRecords = async (page = 1, pageSize = 10, startTime = null, endTime = null) => {
        setGiftLoading(true);
        try {
            const params = {
                page,
                pageSize,
                ...(startTime && { startTime }),
                ...(endTime && { endTime })
            };

            const response = await authAPI.getGiftList(params);
            
            // 确保 response.data.list 是一个数组
            const data = Array.isArray(response.data.list) ? response.data.list : [];
            
            // 处理数据
            const dataWithKeys = data.map(item => ({
                ...item,
                key: item.id,
                // 确保金额显示正确
                payMoney: Number(item.payMoney).toFixed(2)
            }));
            
            setGiftData(dataWithKeys);
            setGiftPagination(prev => ({
                ...prev,
                total: response.data.totalCount || 0,
                current: page,
                pageSize,
            }));
        } catch (error) {
            console.error('获取赠送记录失败:', error);
            setGiftData([]);
        } finally {
            setGiftLoading(false);
        }
    };

    // 处理分页变化
    const handleTableChange = (newPagination, filters, sorter) => {
        getRechargeRecords(newPagination.current, newPagination.pageSize);
    };

    // 处理赠送记录表格分页变化
    const handleGiftTableChange = (newPagination, filters, sorter) => {
        getGiftRecords(
            newPagination.current,
            newPagination.pageSize,
            dateRanges.gift?.[0]?.format('YYYY-MM-DD HH:mm:ss'),
            dateRanges.gift?.[1]?.format('YYYY-MM-DD HH:mm:ss')
        );
    };

    // 处理消费记录表格分页变化
    const handleConsumeTableChange = (newPagination, filters, sorter) => {
        getConsumeRecords(
            newPagination.current,
            newPagination.pageSize,
            dateRanges.consume?.[0]?.format('YYYY-MM-DD HH:mm:ss'),
            dateRanges.consume?.[1]?.format('YYYY-MM-DD HH:mm:ss')
        );
    };

    // 组件加载时获取数据
    useEffect(() => {
        getRechargeRecords();
        getConsumeRecords();
        getAccountInfo();
        getGiftRecords();
    }, [getRechargeRecords]);

    // 值记录表格列配置
    const rechargeColumns = [
        {
            title: '充值时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 180
        },
        {
            title: '订单号',
            dataIndex: 'orderSn',
            key: 'orderSn',
            width: 200
        },
        {
            title: '充值金额',
            dataIndex: 'money',
            key: 'money',
            width: 120,
            render: (money) => `¥${money}`
        },
        {
            title: '支付方式',
            dataIndex: 'mode',
            key: 'mode',
            width: 120,
            render: (mode) => mode === 'wxpay' ? '微信支付' :
                mode === 'alipay' ? '支付宝' : mode
        },

        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: 100,
            render: (state) => (
                <span className={
                    state === '已完成' || state === '已付款' ? styles.statusSuccess :
                    state === '已关闭' || state === '拒绝退款' ? styles.statusError :
                    state === '退款中' || state === '申请退款' ? styles.statusWarning :
                    state === '已退款' ? styles.statusInfo :
                    styles.statusDefault
                }>
                    {state}
                </span>
            )
        }
    ];

    // 消费记录表格列配置
    const consumeColumns = [
        {
            title: '消费时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 180
        },
        {
            title: '消费项目',
            dataIndex: 'title',
            key: 'title',
            width: 160
        },
        {
            title: '游戏',
            dataIndex: 'game',
            key: 'game',
            width: 120
        },
        {
            title: '数量',
            dataIndex: 'count',
            key: 'count',
            width: 80
        },
        {
            title: '消费金额',
            dataIndex: 'money',
            key: 'money',
            width: 120,
            render: (money) => `¥${money}`
        },
        {
            title: '消费积分',
            dataIndex: 'integral',
            key: 'integral',
            width: 120
        }
    ];

    // 赠送记录表格列配置
    const giftColumns = [
        {
            title: '赠送时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 180
        },
        {
            title: '充值金额',
            dataIndex: 'payMoney',
            key: 'payMoney',
            width: 120,
            render: (payMoney) => `¥${payMoney}`
        },
        {
            title: '赠送积分',
            dataIndex: 'gifftIntegral',
            key: 'gifftIntegral',
            width: 120
        }
    ];

    const tabItems = [
        {
            key: 'recharge',
            label: '充值记录',
            children: (
                <Card
                    bordered={false}
                    extra={
                        <DateRangeSelector
                            type="recharge"
                            value={dateRanges.recharge}
                            onChange={handleDateRangeChange}
                        />
                    }
                >
                    <Table
                        columns={rechargeColumns}
                        dataSource={rechargeData}
                        pagination={pagination}
                        onChange={handleTableChange}
                        loading={loading}
                        scroll={{ x: 'max-content' }}
                        rowKey={(record) => record.key}
                    />
                </Card>
            ),
        },
        {
            key: 'consume',
            label: '消费记录',
            children: (
                <Card
                    bordered={false}
                    extra={
                        <DateRangeSelector
                            type="consume"
                            value={dateRanges.consume}
                            onChange={handleDateRangeChange}
                        />
                    }
                >
                    <Table
                        columns={consumeColumns}
                        dataSource={consumeData}
                        pagination={consumePagination}
                        onChange={handleConsumeTableChange}
                        loading={consumeLoading}
                        scroll={{ x: 'max-content' }}
                        rowKey={(record) => record.key}
                    />
                </Card>
            ),
        },
        {
            key: 'gift',
            label: '赠送记录',
            children: (
                <Card
                    bordered={false}
                    extra={
                        <DateRangeSelector
                            type="gift"
                            value={dateRanges.gift}
                            onChange={handleDateRangeChange}
                        />
                    }
                >
                    <Table
                        columns={giftColumns}
                        dataSource={giftData}
                        pagination={giftPagination}
                        onChange={handleGiftTableChange}
                        loading={giftLoading}
                        scroll={{ x: 'max-content' }}
                        rowKey={(record) => record.key}
                    />
                </Card>
            ),
        },
    ];

    return (
        <div className={styles.container}>
            {/* 余额和积分展示 */}
            <Card className={styles.overviewCard}>
                <Row gutter={[24, 24]}>
                    <Col xs={24} sm={24} md={8}>
                        <div className={styles.balanceCard}>
                            <Statistic
                                title="账户余额"
                                value={accountInfo?.money || 0.00}
                                precision={2}
                                prefix={<WalletOutlined />}
                                suffix="元"
                                className={styles.statistic}
                            />
                            <Button
                                type="primary"
                                size="large"
                                onClick={() => navigate('/account/recharge')}
                            >
                                立即充值
                            </Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <div className={styles.pointsCard}>
                            <Statistic
                                title="我的积分"
                                value={accountInfo?.integral || 0}
                                prefix={<GiftOutlined />}
                                className={styles.statistic}
                            />
                        </div>
                    </Col>
                </Row>
            </Card>

            {/* 交易记录表格 */}
            <Card className={styles.recordsCard}>
                <Tabs
                    items={tabItems}
                    className={styles.tabs}
                />
            </Card>
        </div>
    );
};

export default WalletPage; 