import React, { useState, useEffect } from 'react';
import { Card, Descriptions, message } from 'antd';
import { useAuth } from '@/contexts/AuthContext';
import { authAPI } from '@/api';

const Profile = () => {
    const { user } = useAuth();
    const [accountInfo, setAccountInfo] = useState(null);

    const getMyAccount = async () => {
        try {
            const response = await authAPI.getMyAccount();
            if (response.data) {
                setAccountInfo(response.data);
            }
        } catch (error) {
            message.error('获取账户信息失败');
            console.error('获取账户信息失败:', error);
        }
    };

    useEffect(() => {
        getMyAccount();
    }, []);

    return (
        <Card title="个人资料">
            <Descriptions bordered>
                <Descriptions.Item label="用户名">{accountInfo?.username || user.username}</Descriptions.Item>
                <Descriptions.Item label="ID">{accountInfo?.id || user.id}</Descriptions.Item>
                <Descriptions.Item label="手机号">{accountInfo?.mobile || '未设置'}</Descriptions.Item>
                <Descriptions.Item label="余额">{accountInfo?.money || 0}</Descriptions.Item>
                <Descriptions.Item label="积分">{accountInfo?.integral || 0}</Descriptions.Item>
                <Descriptions.Item label="注册时间">{accountInfo?.createdAt || '未知'}</Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

export default Profile; 