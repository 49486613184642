import React, { useState, useEffect } from 'react';
import { Card, Radio, Input, Button, message, Space, Row, Col, Divider, Modal } from 'antd';
import { WalletOutlined, WechatOutlined, AlipayOutlined, QqOutlined } from '@ant-design/icons';
import { authAPI, publicAPI } from '@/api';
import { QRCodeSVG } from 'qrcode.react';
import './Recharge.css';

const Recharge = () => {
    // 状态管理
    const [selectedAmount, setSelectedAmount] = useState(0);    // 选中的充值金额，默认100元
    const [customAmount, setCustomAmount] = useState('');         // 自定义充值金额
    const [loading, setLoading] = useState(false);               // 提交按钮加载状态
    const [paymentMethod, setPaymentMethod] = useState('alipay'); // 支付方式，默认微信支付

    // 网络获取我的账户信息
    const [accountInfo, setAccountInfo] = useState(null);
    const getAccountInfo = async () => {
        const { data } = await authAPI.getMyAccount();
        setAccountInfo(data);
    };

    // 网络获取充值赠送列表
    const [giffList, setGiffList] = useState([]);
    const getGiffList = async () => {
        const { data } = await publicAPI.getGiffList();

        let list = [];
        data.list.map(item => {
            return list.push({
                value: item.payMoney,
                label: item.payMoney,
                points: item.gifftIntegral
            });
        });

        list.push({value: 0, label: '自定义金额', points: 0});
        setGiffList(list);
    };

    // 获取支持的支付方式
    const [paymentMethods, setPaymentMethods] = useState([]);
    const getPaymentMethods = async () => {
        const { data } = await authAPI.getPayInfo();
        let methods = [];
        if(data.alipay) methods.push({ 
            value: 'alipay', 
            label: '支付宝支付', 
            icon: <AlipayOutlined style={{ color: '#1677FF' }} />,
            className: 'alipay-pay'
        });

        if(data.wxpay) methods.push({ 
            value: 'wxpay', 
            label: '微信支付', 
            icon: <WechatOutlined style={{ color: '#07C160' }} />,
            className: 'wechat-pay'
        });

        if(data.qqpay) methods.push({ 
            value: 'qqpay', 
            label: 'QQ支付', 
            icon: <QqOutlined style={{ color: '#12B7F5' }} />,
            className: 'qq-pay'
        });

        setPaymentMethods(methods);
    };

    // 页面初始化
    useEffect(() => {
        getAccountInfo();
        getGiffList();
        getPaymentMethods();
    }, []);

    // 处理充值金额选择变化
    const handleAmountChange = (e) => {
        setSelectedAmount(e.target.value);
        // 如果选择了预设金额，清空自定义金额
        if (e.target.value !== 0) {
            setCustomAmount('');
        }
    };

    // 处理自定义金额输入
    const handleCustomAmountChange = (e) => {
        // 只允许输入数字
        const value = e.target.value.replace(/[^\d]/g, '');
        setCustomAmount(value);
        setSelectedAmount(0); // 切换到自定义金额选项
    };

    // 处理充值提交
    const handleRecharge = async () => {
        // 获取最终充值金额
        const amount = selectedAmount === 0 ? Number(customAmount) : selectedAmount;
        
        // 表单验证
        if (!amount || amount <= 0) {
            message.error('请选择或输入有效的充值金额');
            return;
        }

        if (!paymentMethod) {
            message.error('请选择支付方式');
            return;
        }

        try {
            setLoading(true);
            // TODO: 调用充值API
            const { data } = await authAPI.createOrder({
                // tradeType: "scan",
                orderType: "balance",
                payType: paymentMethod,
                money: amount,
                returnUrl: window.location.href
            });


            // 如果支付方式不是扫码支付，则跳转到支付页面
            if(data.Order.tradeType !== 'scan') {
                // 新建浏览器窗口跳转到返回到Url
                window.open(data.Order.payURL, '_blank');
            }else{
                // 如果是扫码支付，则显示二维码
                // 可以使用 Modal 或者其他方式显示二维码
                Modal.info({
                    title: '请扫码支付',
                    content: (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <QRCodeSVG value={data.Order.payURL} size={256} />
                        </div>
                    ),
                    okText: '关闭',
                    centered: true
                });
            }   
        } catch (error) {
            message.error('充值失败：' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="recharge-container">
            {/* 充值卡片 */}
            <Card 
                title={
                    <Space>
                        <WalletOutlined />
                        <span>账户充值</span>
                    </Space>
                }
                className="recharge-card"
            >
                {/* 账户余额和积分信息 */}
                <div className="balance-info">
                    <div className="balance-item">
                        <h3>当前余额</h3>
                        <div className="balance-amount">¥ {accountInfo?.money || 0.00}</div>
                    </div>
                    <div className="balance-divider" />
                    <div className="balance-item">
                        <h3>当前积分</h3>
                        <div className="points-amount">{accountInfo?.integral || 0}</div>
                    </div>
                </div>

                {/* 充值金额选择区域 */}
                <div className="amount-selection">
                    <h3>选择充值金额</h3>
                    <Radio.Group 
                        onChange={handleAmountChange} 
                        value={selectedAmount}
                        className="amount-options"
                    >
                        <Row gutter={[16, 16]}>
                            {giffList.map(option => (
                                <Col span={8} key={option.value}>
                                    <Radio.Button 
                                        value={option.value}
                                        className="amount-option-button"
                                    >
                                        <div className="amount-option-content">
                                            <div className="amount">{option.label}</div>
                                            {/* 显示赠送积分 */}
                                            {option.points > 0 && (
                                                <div className="bonus-points">
                                                    送{option.points}积分
                                                </div>
                                            )}
                                        </div>
                                    </Radio.Button>
                                </Col>
                            ))}
                        </Row>
                    </Radio.Group>

                    {/* 自定义金额输入框 */}
                    {selectedAmount === 0 && (
                        <div className="custom-amount">
                            <Input
                                prefix="¥"
                                placeholder="请输入充值金额"
                                value={customAmount}
                                onChange={handleCustomAmountChange}
                                maxLength={6}
                            />
                        </div>
                    )}
                </div>

                <Divider />

                {/* 支付方式选择区域 */}
                <div className="payment-method">
                    <h3>选择支付方式</h3>
                    <Radio.Group 
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        value={paymentMethod}
                        className="payment-options"
                    >
                        <Row gutter={[16, 16]}>
                            {paymentMethods.map(method => (
                                <Col span={8} key={method.value}>
                                    <Radio.Button 
                                        value={method.value}
                                        className={`payment-option-button ${method.className}`}
                                    >
                                        <div className="payment-method-content">
                                            {method.icon}
                                            <span>{method.label}</span>
                                        </div>
                                    </Radio.Button>
                                </Col>
                            ))}
                        </Row>
                    </Radio.Group>
                </div>

                {/* 支付金额显示 */}
                <div className="payment-info">
                    <h3>支付金额</h3>
                    <div className="payment-amount">
                        ¥ {selectedAmount === 0 ? (customAmount || '0.00') : `${selectedAmount}`}
                    </div>
                </div>

                {/* 提交按钮 */}
                <Button 
                    type="primary" 
                    size="large" 
                    block 
                    onClick={handleRecharge}
                    loading={loading}
                >
                    立即充值
                </Button>

                {/* 充值说明 */}
                <div className="recharge-notice">
                    <h4>充值说明：</h4>
                    <ul>
                        <li>充值金额将实时到账</li>
                        <li>如遇到充值问题，请联系客服</li>
                        <li>充值金额仅用于平台消费，不支持提现</li>
                    </ul>
                </div>
            </Card>
        </div>
    );
};

export default Recharge;