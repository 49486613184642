import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Divider } from 'antd';
import { 
    GithubOutlined, 
    TwitterOutlined, 
    InstagramOutlined,
    FacebookOutlined,
    LinkedinOutlined
} from '@ant-design/icons';
import './Footer.css';
import logo from '@/assets/logo.png'; // 确保路径正确

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                {/* 主要内容区域 */}
                <Row gutter={[32, 32]}>
                    {/* Logo和简介 */}
                    <Col xs={24} sm={24} md={8} lg={8}>
                        <div className="footer-brand">
                            <img src={logo} alt="Logo" className="footer-logo" />
                            <p className="footer-description">
                                提供全球优质的代理服务，为您的网络访问保驾护航。
                                我们致力于提供最安全、最稳定的网络解决方案。
                            </p>
                            <div className="footer-social">
                                <a href="#" target="_blank" rel="noopener noreferrer"><FacebookOutlined /></a>
                                <a href="#" target="_blank" rel="noopener noreferrer"><TwitterOutlined /></a>
                                <a href="#" target="_blank" rel="noopener noreferrer"><InstagramOutlined /></a>
                                <a href="#" target="_blank" rel="noopener noreferrer"><LinkedinOutlined /></a>
                                <a href="#" target="_blank" rel="noopener noreferrer"><GithubOutlined /></a>
                            </div>
                        </div>
                    </Col>

                    {/* 快速链接 */}
                    <Col xs={24} sm={12} md={5} lg={5}>
                        <div className="footer-section">
                            <h3>快速链接</h3>
                            <ul>
                                <li><Link to="/about">关于我们</Link></li>
                                <li><Link to="/pricing">价格方案</Link></li>
                                <li><Link to="/contact">联系我们</Link></li>
                                <li><Link to="/blog">技术博客</Link></li>
                            </ul>
                        </div>
                    </Col>

                    {/* 服务支持 */}
                    <Col xs={24} sm={12} md={5} lg={5}>
                        <div className="footer-section">
                            <h3>服务支持</h3>
                            <ul>
                                <li><Link to="/help">帮助中心</Link></li>
                                <li><Link to="/faq">常见问题</Link></li>
                                <li><Link to="/terms">服务条款</Link></li>
                                <li><Link to="/privacy">隐私政策</Link></li>
                            </ul>
                        </div>
                    </Col>

                    {/* 联系方式 */}
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <div className="footer-section">
                            <h3>联系我们</h3>
                            <ul className="footer-contact">
                                <li>
                                    <strong>邮箱：</strong>
                                    <a href="mailto:googve@foxmail.com">googve@foxmail.com</a>
                                </li>
                                <li>
                                    <strong>QQ：</strong>
                                    <a>865583446</a>
                                </li>
                                <li>
                                    <strong>工作时间：</strong>
                                    <span>周一至周日 24小时</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                {/* 底部分割线 */}
                <Divider className="footer-divider" />

                {/* 版权信息 */}
                <div className="footer-bottom">
                    <div className="copyright">
                        © {new Date().getFullYear()} Your Company Name. All rights reserved.
                    </div>
                    <div className="footer-links">
                        <Link to="/terms">Terms of Service</Link>
                        <Link to="/privacy">Privacy Policy</Link>
                        <Link to="/security">Security</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer; 