import { Navigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { checkAuth } = useAuth();
    
    if (!checkAuth()) {
        return <Navigate to="/" replace />;
    }

    return children;
};

export default ProtectedRoute; 