// API 基础配置
export const API_BASE_URL = '';  // 直接使用相对路径

export const API_TIMEOUT = 15000; // 15 seconds

export const API_HEADERS = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'HotDomain': 'www.1637.net'
}; 
