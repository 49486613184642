import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { 
    UserOutlined, 
    WalletOutlined, 
    UserAddOutlined,
    SettingOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined
} from '@ant-design/icons';
import './AccountLayout.css';

const { Content, Sider } = Layout;

const AccountLayout = () => {
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setCollapsed(true);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const menuItems = [
        {
            key: 'profile',
            icon: <UserOutlined />,
            label: <Link to="/account/profile">仪表台</Link>
        },
        {
            key: 'proxy',
            icon: <UserOutlined />,
            label: <Link to="/account/proxy">我的代理</Link>
        },
        {
            key: 'recharge',
            icon: <WalletOutlined />,
            label: <Link to="/account/recharge">在线充值</Link>
        },
        {
            key: 'promotion',
            icon: <UserAddOutlined />,
            label: <Link to="/account/promotion">推广中心</Link>
        },
        {
            key: 'wallet',
            icon: <WalletOutlined />,
            label: <Link to="/account/wallet">我的钱包</Link>
        },
        {
            key: 'settings',
            icon: <SettingOutlined />,
            label: <Link to="/account/settings">账户设置</Link>
        },
    ];

    const selectedKey = location.pathname.split('/')[2] || 'profile';

    return (
        <>
            <Button
                className="menu-trigger"
                type="primary"
                onClick={() => setCollapsed(!collapsed)}
                style={{
                    left: collapsed ? '0' : '200px'
                }}
            >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Button>

            <Layout className="account-layout">
                <Sider 
                    width={200} 
                    theme="light"
                    collapsed={collapsed}
                    collapsedWidth={0}
                    className="account-sider"
                >
                    <Menu
                        mode="inline"
                        selectedKeys={[selectedKey]}
                        items={menuItems}
                        className="account-menu"
                    />
                </Sider>

                <Layout 
                    className="account-main"
                    style={{ 
                        marginLeft: collapsed ? 0 : 200,
                        transition: 'margin-left 0.2s'
                    }}
                >
                    <Content className="account-content">
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </>
    );
};

export default AccountLayout; 